import React, { useEffect, useState } from "react";

const UploadFileExtra = ({
    initValue,
    changeHandler
}) => {

    const [files, setFiles] = useState([]);
    const [isOver, setIsOver] = useState(false);

    useEffect(() => {
        console.log("UploadFileExtra_initValue:", initValue);
        if(!initValue) {
            setFiles([]);
        } else {
            setFiles(initValue);
        }
    }, [initValue])

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsOver(true);
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsOver(false);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        setIsOver(false);

        if (e.dataTransfer.files.length != 0) {
            const tmp_files = [...files, e.dataTransfer.files[0]];
            changeHandler(tmp_files);
            setFiles(tmp_files);
        }
    }

    return (
        <div className="font-mulish flex flex-col md:flex-row gap-[10px] md:gap-[20px] items-end md:items-center">
            <div
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className={`w-full md:w-[450px] h-[150px] flex flex-col gap-[10px] justify-center items-center border-2 border-dashed border-dark_grey rounded-[5px]`}>
                <span className={`text-[20px] text-dark_grey font-[400]`}>Extra Documents</span>
                <span className={`text-[18px] text-dark_grey font-[400]`}>Drop documents here</span>
            </div>
            {files && files.map((file, index) => {
                return (
                    <div className="flex gap-[5px] items-center">
                        <span className="max-w-[150px] text-[14px] text-[#8D8D8D] font-[400] text-end md:text-start overflow-hidden">{typeof(file) != 'object' ? file : file.name}</span>
                        <button onClick={(e) => { 
                            const tmp_files = [...files];
                            tmp_files.splice(index, 1);
                            changeHandler(tmp_files);
                            setFiles(tmp_files);
                        }} className="w-[30px] h-[30px] flex justify-center items-center bg-[#E6E6E6] rounded-full p-[10px]">
                            <span className="text-[10px] font-[500] text-[#54586C]">x</span>
                        </button>
                    </div>
                )
            })}
        </div>
    );
}

export default UploadFileExtra;