import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";

import { UserContext } from "../context/user-context";
import { LoadingContext } from "../context/loading-context";

import Input from "../components/Basic/Input";
import Button from "../components/Basic/Button";
import { addContract } from "../utils/restService";

import { toast } from "react-toastify";
import CheckBox from "../components/Basic/CheckBox";
import TextArea from "../components/Basic/TextArea";

const Contract = () => {

    const navigate = useNavigate();

    const { userInfo } = useContext(UserContext);
    const { setIsLoading } = useContext(LoadingContext);

    const [secondContract, setSecondContract] = useState(false);

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [zip, setZIP] = useState("");
    const [place, setPlace] = useState("");
    const [locationCustomer, setLocationCustomer] = useState("");
    const [datum, setDatum] = useState("");

    const [secondLastnameFirstname, setSecondLastnameFirstname] = useState("");
    const [secondStreetNo, setSecondStreetNo] = useState("");
    const [secondZipPlace, setSecondZipPlace] = useState("");
    const [secondBirthday, setSecondBirthday] = useState("");
    const [secondSubsequentProperty, setSecondSubsequentProperty] = useState("");
    const [secondOrtDatum, setSecondOrtDatum] = useState("");
    const [secondNameVorname, setSecondNameVorname] = useState("");
    const [secondCommentAdviser, setSecondCommentAdviser] = useState("");

    const onAddContract = async (user_id, name, address, zip, place, locationCustomer, datum, secondLastnameFirstname, secondStreetNo, secondZipPlace, secondBirthday, secondSubsequentProperty, secondOrtDatum, secondNameVorname, secondCommentAdviser) => {
        if (!name || !address || !zip || !place || !locationCustomer || !datum || (secondContract && (!secondLastnameFirstname || !secondStreetNo || !secondZipPlace || !secondBirthday || !secondSubsequentProperty || !secondOrtDatum || !secondNameVorname || !secondCommentAdviser))) {
            toast.error("Please Input Values!");
            return;
        }

        setIsLoading(true);
        const res = await addContract(user_id, secondContract, name, address, zip, place, locationCustomer, datum, secondLastnameFirstname, secondStreetNo, secondZipPlace, secondBirthday, secondSubsequentProperty, secondOrtDatum, secondNameVorname, secondCommentAdviser);

        if (res.result) {
            toast.success("Submit Contract Document successfully!");
            navigate("/products");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    return (
        <div className="font-poppins w-full h-auto min-h-[100vh] flex flex-col items-center bg-back pt-[225px] md:pt-[180px] pb-[60px] overflow-auto">
            <div className="max-w-[1400px] w-full h-full flex flex-col gap-[20px] justify-center items-center px-[20px]">
                <span className="text-[24px] text-dark_grey font-[700] text-center px-[20px] md:px-[80px] py-[10px]">Dienstleistungsvertrag nur für Ausschreibungen von Hypotheken</span>
                {/* <div className="w-full h-full flex flex-col md:flex-row gap-[10px] justify-between items-center px-[40px] md:px-[80px] py-[30px] bg-white rounded-[10px]">
                    <div className="w-full flex flex-row gap-[10px] justify-between md:justify-start items-center">
                        <span className="text-[14px] text-dark_grey">Name:</span>
                        <Input size="sm" placeholder="Name" changeHandler={(value) => setName(value)} />
                    </div>
                    <div className="w-full flex flex-row gap-[10px] justify-between md:justify-start items-center">
                        <span className="text-[14px] text-dark_grey">Adresse:</span>
                        <Input size="sm" placeholder="Adresse" changeHandler={(value) => setAddress(value)} />
                    </div>
                    <div className="w-full flex flex-row gap-[10px] justify-between md:justify-start items-center">
                        <span className="text-[14px] text-dark_grey">PLZ:</span>
                        <Input size="sm" placeholder="PLZ" changeHandler={(value) => setZIP(value)} />
                    </div>
                    <div className="w-full flex flex-row gap-[10px] justify-between md:justify-start items-center">
                        <span className="text-[14px] text-dark_grey">Ort:</span>
                        <Input size="sm" placeholder="Ort" changeHandler={(value) => setPlace(value)} />
                    </div>
                </div> */}
                <div className="w-full h-full flex flex-col gap-[10px] px-[40px] md:px-[80px] py-[30px] md:py-[60px] bg-white rounded-[10px]">
                    <div className="w-full h-[2px] bg-light_grey" />
                    <div className="flex flex-col gap-[40px]">
                        <span className="text-[16px] text-dark_grey font-[700]">Dienstleistungsvertrag über die Erbringung von Beratungs- und Vermittlungsleistungen</span>
                        <div className="flex flex-col gap-[20px]">
                            <span className="text-[14px] text-dark_grey">Zwischen</span>
                            <div className="flex flex-col gap-[10px]">
                                <span className="w-full text-[16px] text-dark_grey font-[700]">DKI Schweiz</span>
                                <span className="w-full text-[14px] text-dark_grey">Benjamin Koch</span>
                                <span className="w-full text-[14px] text-dark_grey">Bodenhofterrasse 22</span>
                                <div className="w-full flex flex-row justify-between">
                                    <span className="text-[14px] text-dark_grey">6005 Luzern</span>
                                    <span className="text-[12px] text-dark_grey text-right">- nachfolgend: «<b>DKI</b>»</span>
                                </div>
                            </div>
                            <span className="w-full text-[14px] text-dark_grey">und</span>
                            <div className="flex flex-col gap-[10px]">
                                {/* <span className="w-full text-[14px] text-dark_grey">{name}</span> */}
                                <Input size="md" placeholder="Name" changeHandler={(value) => setName(value)} />
                                {/* <span className="w-full text-[14px] text-dark_grey">{address}</span> */}
                                <Input size="md" placeholder="Adresse" changeHandler={(value) => setAddress(value)} />
                                <div className="w-full flex flex-col md:flex-row gap-[10px] justify-between">
                                    <div className="flex flex-row gap-[20px]">
                                        {/* <span className="text-[14px] text-dark_grey">{zip}</span> */}
                                        <Input size="sm" placeholder="PLZ" changeHandler={(value) => setZIP(value)} />
                                        {/* <span className="text-[14px] text-dark_grey">{place}</span> */}
                                        <Input size="sm" placeholder="Ort" changeHandler={(value) => setPlace(value)} />
                                    </div>
                                    <span className="text-[12px] text-dark_grey text-right">- nachfolgend: «<b>Auftraggeber*</b>»</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-[2px] bg-light_grey" />
                    <div className="flex flex-col gap-[20px] pt-[30px] pb-[20px]">
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">1</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Vertragsgegenstand</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                            <span className="pl-[10px] text-[14px] text-dark_grey">- Ausschreibung und Vermittlung einer Hypothekenfinanzierung</span>
                            <span className="text-[14px] text-dark_grey">DKI schuldet ein sorgfältiges Tätigwerden, um dem Auftraggeber nach seinen bestmöglichen Bemühungen zu einer für ihn geeigneten Finanzierungslösung zu verhelfen.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">2</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Vertragsbestandteile</span>
                            </div>
                            <div className="flex flex-col gap-[5px]">
                                <span className="text-[14px] text-dark_grey">Die folgenden Dokumente bilden integrierender Bestandteil dieser Vereinbarung:</span>
                                <span className="pl-[10px] text-[14px] text-dark_grey">- Allgemeine Geschäftsbedingungen von DKI (Version 04.12.2023)</span>
                                <span className="pl-[10px] text-[14px] text-dark_grey">- Datenschutzerklärung von DKI</span>
                                <span className="pl-[10px] text-[14px] text-dark_grey">- Servicevereinbarung SwissFex</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">Im Fall von Widersprüchen geht diese Vereinbarung inklusive Anhang I vor. Der Auftraggeber bestätigt mit der Unterzeichnung dieser Vereinbarung, dass er im Besitz aller genannten Vertragsbestandteile ist.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">3</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Vertragsbeginn | Vertragsdauer</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">Der Vertrag beginnt nach Unterzeichnung dieses Vertrages durch die Parteien und richtet sich nach dem gemeinsam festgelegten Zeitplan. Die Vertragsdauer ist unbefristet und endet spätestens mit Erreichen der gemäss Ziffer 1 definierten Punkte oder durch Kündigung einer Partei.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">4</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Zeit und Ort der Leistungserbringung</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">DKI bestimmt seinen Arbeitsort und seine Arbeitszeit eigenverantwortlich bzw. im Einzelfall oder bei besonderem Bedarf einvernehmlich mit dem Auftraggeber.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">5</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Informationspflicht gegenüber Auftraggeberbers</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">DKI hält seinen Auftraggeber über den Stand der Bemühungen informiert.</span>
                            <span className="text-[14px] text-dark_grey">Der Nachweis der Bemühungen im Sinne von Ziffer 1 dieses Vertrages erfolgt mündlich oder schriftlich (auch per E-Mail, sofern der Auftraggeber eine Zustellung per E-Mail nicht abgelehnt hat). Der Nachweis erfolgt entsprechend dem Arbeitsfortschritt in Form von Zwischennachweisen, und zwar immer dann, wenn sich für die Umsetzung gemäss Ziffer 1 dieses Vertrages neue Aspekte ergeben haben, die für die Umsetzung entscheidend sind.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">6</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Mitwirkungspflichten des Auftraggebers</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">Der Auftraggeber ist verpflichtet, DKI bestmöglich zu unterstützen und alle zur ordnungsgemässen Beratung und Vermittlung von Finanzierungen notwendigen Voraussetzungen und Unterlangen DKI zur Verfügung zu stellen. Dies gilt auch für Informationen und Unterlagen, die während der Tätigkeit von DKI bekannt werden.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">7</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Vergütung von DKI</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">Bei einer erfolgreichen Vermittlung erhält DKI in der Regel eine Entschädigung des entsprechenden Finanzinstitutes. Der Auftraggeber verzichtet daher auf die Rückforderung von allfälligen Courtagen und Provisionen von Dritten, da für ihn keine Kosten mit diesem Auftrag entstehen.</span>
                            <span className="text-[14px] text-dark_grey">Allfällige Reisekosten werden nur gemäss vorgängiger Absprache ab einer Distanz von 50 km pro Weg mit 70 Rappen pro gefahrenen Kilometer berechnet. Im Falle der Benutzung des öffentlichen Verkehrs werden die effektiv angefallenen Billetkosten für die 2. Klasse dem Auftraggeber in Rechnung gestellt. Die jeweilige Spesenabrechnung ist sofort zahlbar.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">8</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Haftung bei Angebotsannahme und Refinanzierung</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">Entschliesst sich der Auftraggeber, ein Angebot zu den vereinbarten Konditionen anzunehmen, gilt dies als verbindlich, da der Kreditgeber die entsprechende Refinanzierung fixiert. Tritt der Auftraggeber nach Angebotsannahme vom Vertrag mit dem Kreditgeber zurück, haftet dieser dem Kreditgeber gegenüber für eine allfällige Vorfälligkeitsentschädigung und verpflichtet sich, DKI vollumfänglich schadlos zu halten.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">9</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Vertraulichkeit und Datenschutz</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">Alle Informationen, die im Rahmen dieses Vertrages von einer Partei an die andere übergeben werden, werden durch die Parteien strikt vertraulich behandelt und dürfen ausserhalb der Erfüllung dieses Vertrages und unter Vorbehalt gesetzlicher Pflichten nicht an Dritte weitergegeben werden. Der Auftraggeber stimmt zu, dass DKI zur Erfüllung dieses Vertrages die Unterlagen zu den finanziellen Verhältnissen, dem zu finanzierenden Objekt und allfällige weitere verlangte Unterlagen an die in Frage kommenden potenziellen Kreditgeber weitergeben darf.</span>
                            <span className="text-[14px] text-dark_grey">Die gewonnenen Beratungs- und Vermittlungsergebnisse dürfen vom Auftraggeber ausdrücklich nur für sich persönlich und/oder sein Unternehmen verwendet werden. Vor einer Weitergabe an andere Unternehmen oder Dritte ist eine vorherige schriftliche Zustimmung von DKI einzuholen.</span>
                            <span className="text-[14px] text-dark_grey">DKI bearbeitet die ihr anvertrauten Personendaten grundsätzlich nur im Rahmen ihrer Tätigkeit im Zusammenhang mit der Erfüllung oder Anbahnung dieses Vertrages oder gegebenenfalls zu gesetzlich vorgeschriebenen Zwecken. Weitere Informationen zu Vertraulichkeit und Datenschutz findet der Auftraggeber in den AGB und der Datenschutzerklärung von DKI, welche unter  finanzanfrage.ch/datenschutz nachgelesen werden können.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">10</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Kündigung / Widerruf</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">Der Vertrag kann jederzeit aus wichtigem Grund ohne Einhaltung einer Frist, ansonsten mit einer Frist von 14 Tagen von jeder der beiden Parteien zum Monatsende gekündigt werden. Die vereinbarten Honorierungen gemäss Anhang I bleiben aber auch bei einer vorzeitigen Kündigung durch den Auftraggeber geschuldet.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">11</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Aufbewahrung und Rückgabe von Unterlagen</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">DKI verpflichtet sich, alle ihm zur Verfügung gestellten Geschäfts- und Betriebsunterlagen ordnungsgemäss aufzubewahren, insbesondere dafür zu sorgen, dass Dritte nicht Einsicht nehmen können. Die zur Verfügung gestellten Unterlagen sind während der Dauer des Vertrages und nach Beendigung des Vertrages auf Anforderung des Auftraggebers an diesen zurückzugeben.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">12</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Sorgfaltspflicht und Haftung</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">DKI führt alle ihr übertragenen Aufgaben und Beratungs- und Vermittlungsleistungen mit grösster Sorgfalt, unter Berücksichtigung der individuellen Situation und der Bedürfnisse des Auftraggebers, durch. DKI schliesst eine allfällige Haftung, unabhängig von ihrem Rechtsgrund, durch DKI selbst oder allfällige beigezogene Hilfspersonen aus, soweit dies gesetzlich zulässig ist.</span>
                        </div>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex flex-row gap-[20px]">
                                <span className="text-[14px] text-dark_grey font-[700]">13</span>
                                <span className="text-[14px] text-dark_grey font-[700]">Schlussbestimmungen</span>
                            </div>
                            <span className="text-[14px] text-dark_grey">Mündliche Nebenabreden zu diesem Vertrag sind nicht getroffen. Änderungen oder Ergänzungen des Vertrages bzw. dessen Anlagen bedürfen zu ihrer Rechtswirksamkeit der Schriftform. Mit Unterzeichnung dieses Vertrages akzeptiert der Auftraggeber die aktuell geltenden Allgemeinen Geschäftsbedingungen von DKI, welche der Auftraggeber unter finanzanfrage.ch/agb zur Kenntnis nehmen konnte oder ihm zugestellt worden sind.</span>
                            <span className="text-[14px] text-dark_grey">Die Parteien sind sich darüber einig, dass für sämtliche wechselseitigen Ansprüche aus diesem Vertrag ausschliesslich schweizerisches Recht gilt, insbesondere die Bestimmungen nach Art. 394 ff. OR ergänzend zur Anwendung kommen. Soweit gesetzlich möglich, vereinbaren die Parteien bereits jetzt als Gerichtsstand Luzern.</span>
                            <span className="text-[14px] text-dark_grey">Sind oder werden einzelne Bestimmungen dieses Vertrages ganz oder teilweise unwirksam, so werden die übrigen Bestimmungen hierdurch nicht berührt. Im Falle der Unwirksamkeit einer solchen Bestimmung bzw. im Fall des Vorliegens von Lücken werden die Vertragsparteien eine  er unwirksamen Regelung bzw. der Gesamtregelung wirtschaftlich möglichst nahekommende rechtswirksame Ersatz- bzw. Ergänzungsregelung treffen.</span>
                        </div>
                    </div>
                    <div className="w-full h-[2px] bg-light_grey" />
                    <div className="w-full flex flex-col md:flex-row gap-[30px] justify-between py-[20px]">
                        <div className="w-full md:w-1/2 flex flex-col">
                            <div className="w-full h-[150px] flex flex-col gap-[20px] border-b border-b-dark_grey">
                                <div className="flex flex-row gap-[10px]">
                                    <Input size="sm" placeholder="Ort Kunde" changeHandler={(value) => setLocationCustomer(value)} />
                                    <Input size="sm" placeholder="Datum" changeHandler={(value) => setDatum(value)} />
                                </div>
                            </div>
                            <div className="w-full h-[150px] flex flex-col gap-[20px]">
                                <span className="text-[14px] text-dark_grey">Unterschrift Auftraggeber</span>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 flex flex-col">
                            <div className="w-full h-[150px] flex flex-col gap-[20px] border-b border-b-dark_grey">
                                <span className="text-[14px] text-dark_grey">Luzern,</span>
                            </div>
                            <div className="w-full h-[150px] flex flex-col gap-[20px]">
                                <span className="text-[14px] text-dark_grey">Unterschrift Auftraggeber</span>
                                <span className="text-[14px] text-dark_grey"><b>DKI Schweiz - </b>Benjamin Koch</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-[2px] bg-light_grey" />
                </div>
                <div className="flex flex-row gap-[10px] items-center">
                    <CheckBox clickHandler={(e, checked) => setSecondContract(checked)} />
                    <span className="text-[14px] text-dark_grey">Include Servicevereinbarung</span>
                </div>
                {secondContract && <div className="w-full h-full flex flex-col gap-[10px] px-[40px] md:px-[80px] py-[30px] md:py-[60px] bg-white rounded-[10px]">
                    <div className="flex flex-col md:flex-row gap-[20px] justify-start md:justify-between items-center md:items-end">
                        <span className="text-[24px] text-dark_grey font-[700]">Servicevereinbarung</span>
                        <div className="order-first md:order-last flex flex-col gap-[10px] items-center">
                            <img className="w-[61px] h-[54px] object-contain" src="/images/icon.png" alt="icon" />
                            <img className="w-[114px] h-[14px] object-contain" src="/images/icon_text_dark.png" alt="icon_text_dark" />
                        </div>
                    </div>
                    <div className="flex flex-col gap-[40px]">
                        <div className="hidden md:flex flex-col gap-[5px]">
                            <span className="text-[16px] text-dark_grey font-[700]">Der/die Unterzeichnende/-n («Antragsteller») beauftragt/beauftragen hiermit die DKI</span>
                            <span className="text-[16px] text-dark_grey font-[700]">Schweiz - Benjamin Koch mit der Verhandlung und Vermittlung seiner/iher Hypothek.</span>
                        </div>
                        <div className="flex md:hidden flex-col gap-[5px]">
                            <span className="text-[16px] text-dark_grey font-[700]">Der/die Unterzeichnende/-n («Antragsteller») beauftragt/beauftragen hiermit die DKI Schweiz - Benjamin Koch mit der Verhandlung und Vermittlung seiner/iher Hypothek.</span>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex flex-col md:flex-row gap-[10px] items-start md:items-center">
                                <div className="w-full md:w-[250px] flex flex-col">
                                    <span className="text-[14px] text-dark_grey">Nachname/-n, Vorname/-n</span>
                                    <span className="text-[10px] text-dark_grey">bzw. Firma und deren Vertreter</span>
                                </div>
                                <Input size="lg" placeholder="" changeHandler={(value) => setSecondLastnameFirstname(value)} />
                            </div>
                            <div className="flex flex-col md:flex-row gap-[10px] items-start md:items-center">
                                <span className="w-full md:w-[250px] text-[14px] text-dark_grey">Stresse, Nr.</span>
                                <Input size="lg" placeholder="" changeHandler={(value) => setSecondStreetNo(value)} />
                            </div>
                            <div className="flex flex-col md:flex-row gap-[10px] items-start md:items-center">
                                <span className="w-full md:w-[250px] text-[14px] text-dark_grey">PLZ, Ort</span>
                                <Input size="lg" placeholder="" changeHandler={(value) => setSecondZipPlace(value)} />
                            </div>
                            <div className="flex flex-col md:flex-row gap-[10px] items-start md:items-center">
                                <span className="w-full md:w-[250px] text-[14px] text-dark_grey">Geburtsdatum (pro Person)</span>
                                <Input size="lg" placeholder="" changeHandler={(value) => setSecondBirthday(value)} />
                            </div>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <span className="w-full text-[14px] text-dark_grey">für voraussichtlich nachfolgende Liegenschaft:</span>
                            <TextArea size="lg" placeholder="" changeHandler={(value) => setSecondSubsequentProperty(value)} />
                            <span className="text-[10px] text-dark_grey">(Angaben sofern bekannt: Art der Liegenschaft, z.B. EFH oder Wohnung, Ort, vorassichtliche Höhe der Hypothek)</span>
                        </div>
                    </div>
                    <div className="w-full h-[2px] bg-light_grey" />
                    <div className="flex flex-col gap-[20px] pt-[30px] pb-[20px]">
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey font-[700]">Vertragsgegenstand</span>
                            <span className="text-[14px] text-dark_grey">Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey font-[700]">Vertragsgegenstand</span>
                            <span className="text-[14px] text-dark_grey">Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey font-[700]">Vertragsgegenstand</span>
                            <span className="text-[14px] text-dark_grey">Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey font-[700]">Vertragsgegenstand</span>
                            <span className="text-[14px] text-dark_grey">Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey font-[700]">Vertragsgegenstand</span>
                            <span className="text-[14px] text-dark_grey">Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey font-[700]">Vertragsgegenstand</span>
                            <span className="text-[14px] text-dark_grey">Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey font-[700]">Vertragsgegenstand</span>
                            <span className="text-[14px] text-dark_grey">Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey">- Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                            <span className="text-[14px] text-dark_grey">- Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                            <span className="text-[14px] text-dark_grey">- Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                            <span className="text-[14px] text-dark_grey">- Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                            <span className="text-[14px] text-dark_grey">- Der Auftraggeber erteilt hiermit <b>DKI</b> exklusiv den <b>Auftrag</b>, ihn bei folgenden Entscheidungen und Vorhaben zu beraten und zu begleiten:</span>
                        </div>
                    </div>
                    <div className="w-full h-[2px] bg-light_grey" />
                    <div className="w-full flex flex-col md:flex-row gap-[30px] justify-between py-[20px]">
                        <div className="w-full md:w-1/2 flex flex-col">
                            <div className="w-full h-[150px] flex flex-col justify-end gap-[20px] border-b border-b-dark_grey">
                                <Input size="lg" placeholder="" changeHandler={(value) => setSecondOrtDatum(value)} />
                            </div>
                            <div className="w-full h-[100px] flex flex-col gap-[20px]">
                                <span className="text-[14px] text-dark_grey">Ort, Datum</span>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 flex flex-col">
                            <div className="w-full h-[150px] flex flex-col gap-[20px] border-b border-b-dark_grey">
                                <span className="text-[14px] text-dark_grey"></span>
                            </div>
                            <div className="w-full h-[100px] flex flex-col gap-[20px]">
                                <span className="text-[14px] text-dark_grey">Unterschrift Auftraggeber</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row gap-[30px] p-[10px] border border-dark_grey">
                        <div className="w-full md:w-1/2 flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey font-[700]">Berater/Beraterin</span>
                            <div className="h-[200px] flex flex-col justify-between">
                                <div className="flex flex-col md:flex-row gap-[10px] border-b border-b-dark_grey">
                                    <span className="w-full md:w-[200px] text-[14px] text-dark_grey">Name, Vorname</span>
                                    <Input size="lg" placeholder="" changeHandler={(value) => setSecondNameVorname(value)} />
                                </div>
                                <span className="text-[14px] text-dark_grey border-b border-b-dark_grey">Unterschrift</span>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 flex flex-col gap-[10px]">
                            <span className="text-[14px] text-dark_grey">Bemerkung Verater/-in:</span>
                            <TextArea size="lg" placeholder="" changeHandler={(value) => setSecondCommentAdviser(value)}/>
                        </div>
                    </div>
                    <div className="w-full h-[2px] bg-light_grey" />
                </div>
                }
                <div className="flex flex-row justify-center">
                    <Button mode="light" size="md" label="Submit" clickHandler={(e) => { onAddContract(userInfo._id, name, address, zip, place, locationCustomer, datum, secondLastnameFirstname, secondStreetNo, secondZipPlace, secondBirthday, secondSubsequentProperty, secondOrtDatum, secondNameVorname, secondCommentAdviser); }} />
                </div>
            </div>
        </div>
    );
}

export default Contract;