import React, { useEffect, useState } from "react";

import Datepicker from "react-tailwindcss-datepicker";

const DateInput = ({
    index,
    initValue,
    size,
    changeHandler
}) => {

    const [selectedDate, setSelectedDate] = useState();

    useEffect(() => {
        setSelectedDate(initValue ? { startDate: initValue, endDate: initValue } : { startDate: "", endDate: "" });
    }, [initValue, index])

    return (
        <div className={`${size == "sm" ? 'w-[140px]' : 'w-[300px] md:w-[350px]'} h-[70px] flex justify-center items-center bg-light_grey rounded-[5px] px-[30px] py-[20px]`}>
            <Datepicker
                inputClassName="bg-light_grey focus:outline-none"
                placeholder="Select a date"
                useRange={false}
                asSingle={true}
                value={selectedDate}
                onChange={(newValue) => { setSelectedDate(newValue); changeHandler(newValue); }}
            />
        </div>
    );
}

export default DateInput;