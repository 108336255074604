import React, { useEffect, useState, useRef } from "react";

const CheckBox = ({
    initValue,
    clickHandler
}) => {

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(initValue);
    }, [initValue])

    return (
        <button onClick={(e) => { clickHandler(e, !checked); setChecked(!checked) }} className="w-[20px] h-[20px] flex justify-center items-center bg-[#E6E6E6] rounded-[5px]">
            {checked && <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 12.5 10 16l7.5-7.5" stroke="currentColor" stroke-width="1.5"/></svg>}
        </button>
    );
}

export default CheckBox;