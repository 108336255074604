import React, { useEffect, useState } from "react";

const TextArea = ({
    index,
    initValue,
    size,
    placeholder,
    changeHandler
}) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(initValue ? initValue : "");
    }, [initValue, index])

    return (
        <textarea value={value} onChange={(e) => { setValue(e.target.value); changeHandler(e.target.value); }} placeholder={placeholder} className={`${size == "sm" ? 'w-[140px]' : (size == "md" ? 'w-[300px]' : 'w-full')} h-[200px] bg-light_grey rounded-[5px] px-[30px] py-[20px] focus:outline-none resize-none overflow-auto`} />
    );
}

export default TextArea;