import React, { createContext, useEffect, useState, useContext } from "react";
import { updateAnswer, updateFile } from "../utils/restService";

function useSubmitContext() {
    const [answers, setAnswers] = useState({});
    const [files, setFiles] = useState({});

    const resetSubmitData = (answers, files) => {
        console.log("resetSubmitData", answers, files);
        setAnswers(answers);
        setFiles(files);
    }

    const updateAnswers = async (step, question, answer, user_id, product_id) => {
        console.log("updateAnswers", step, question, answer);
        let tmp = { ...answers };
        tmp[step] = { question, answer };
        setAnswers(tmp);

        await updateAnswer(
            {
                user_id,
                product_id,
                step,
                new_answer: JSON.stringify({ question, answer }),
            }
        );
    }

    const updateFiles = async (label, new_files, user_id, product_id) => {
        console.log("updateFiles", label, new_files);
        let tmp = { ...files };
        tmp[label] = new_files;
        setFiles(tmp);

        await updateFile(
            {
                user_id,
                product_id,
                new_file: { label, new_files },
            }
        );
    }

    return {
        answers: answers,
        updateAnswers: updateAnswers,
        files: files,
        updateFiles: updateFiles,
        resetSubmitData: resetSubmitData
    };
}
export const SubmitContext = createContext();

export const SubmitProvider = (props) => {
    const submitContext = useSubmitContext();

    return (
        <div>
            <SubmitContext.Provider value={{ ...submitContext }}>
                {props.children}
            </SubmitContext.Provider>
        </div>
    );
};
