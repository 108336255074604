import React, { useEffect, useState } from "react";

const ViewPdfPrint = ({
    src
}) => {

    return (
        <div className="relative font-mulish w-full md:w-[700px] h-[80vh] flex flex-col md:flex-row bg-white rounded-[5px] p-[20px] z-10">
            <iframe className="w-full h-full" id="pdf-iframe" src={`${process.env.REACT_APP_SERVER_URL}/documents/${src}`} frameborder="0"></iframe>
        </div>
    );
}

export default ViewPdfPrint;