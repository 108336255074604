import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { UserContext } from "../context/user-context";
import { LoadingContext } from "../context/loading-context";

import Button from '../components/Basic/Button';
import ProductPanel from '../components/Products/ProductPanel';

const Home = () => {

    const navigate = useNavigate();

    const { userInfo, isLogin, signup, login, logout } = useContext(UserContext);
    const { setIsLoading } = useContext(LoadingContext);

    return (
        <div className="font-poppins w-full flex flex-col bg-back">
            <div className="relative h-[100vh] flex flex-col items-center">
                <img className="absolute top-0 left-0 w-full h-full object-cover" src="/images/bg.png" alt="" />
                <div className="max-w-[1400px] w-full h-full px-[20px] flex flex-col z-10">
                    <div className="flex flex-col md:flex-row gap-[10px] justify-start md:justify-between items-center py-[40px]">
                        <div className="flex gap-[10px] items-center">
                            <img className="w-[83px] h-[74px] object-contain" src="/images/icon.png" alt="icon" />
                            <img className="w-[157px] h-[19px] object-contain" src="/images/icon_text_dark.png" alt="icon_text_dark" />
                        </div>
                        {isLogin ? <div className="flex gap-[30px]">
                            {userInfo.role == "admin" && <button onClick={(e) => { navigate("/dashboard") }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Dashboard</button>}
                            {userInfo.role != "admin" && <button onClick={(e) => { navigate("/user_dashboard") }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Dashboard</button>}
                            <button onClick={(e) => { navigate("/contract") }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Vertragwesen</button>
                            <button onClick={(e) => { navigate("/myaccount") }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Mein Konto</button>
                            <button onClick={(e) => { logout(); }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Ausloggen</button>
                        </div> :
                            <div className="flex gap-[30px]">
                                <Button mode="light" size="sm" label="Registrierung" clickHandler={(e) => { signup(); }} />
                                <Button mode="dark" size="sm" label="Login" clickHandler={(e) => { login(); }} />
                            </div>
                        }
                    </div>
                    <div className="h-full flex flex-col justify-center">
                        <div className="h-full md:h-2/3 flex flex-col gap-[30px] items-center md:items-start">
                            <div className="flex flex-col gap-[10px] items-center md:items-start">
                                <div className="flex gap-[20px] md:gap-[40px] pl-[10px]">
                                    <div className="relative flex justify-center items-center">
                                        <div className="absolute w-[100px] md:w-[150px] h-[45px] md:h-[70px] bg-dark_blue rotate-[4.5deg]" />
                                        <span className="text-white text-[40px] md:text-[60px] font-[700] z-10">DKI</span>
                                    </div>
                                    <span className="text-light_blue text-[40px] md:text-[60px] font-[700]">Schweiz</span>
                                </div>
                                <span className="max-w-auto max-w-[660px] text-dark_blue text-[20px] md:text-[30px] font-[400] text-center md:text-start">Dein Spezialist für Unternehmens- und Immobilienfinanzierung</span>
                            </div>
                            <Button mode="dark" size="sm" label="Los legen" clickHandler={(e) => { navigate("/products"); }} />
                            <span className="max-w-auto max-w-[660px] text-dark_blue text-[16px] md:text-[18px] font-[400] text-center md:text-start">Wir bieten persönliche Beratung in Unternehmens- und Immobilienfinanzierung, um Sie durch die Komplexität der Finanzbranche zu führen. Als Ihr persönlicher Berater legen wir grossen Wert darauf, dass jede Finanzentscheidung präzise auf Ihre individuellen Bedürfnisse abgestimmt ist. Unser Hauptanliegen ist es, Ihnen nicht nur das optimale Produkt mit den besten Konditionen zu sichern, sondern Sie auch konstant und umfassend während des gesamten Finanzierungsprozesses zu unterstützen.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-full min-h-[100vh] flex flex-col items-center">
                <div className="relative max-w-[1200px] w-full h-full px-[20px] py-[100px] flex flex-col">
                    <ProductPanel setIsLoading={setIsLoading} />
                </div>
            </div>
        </div>
    );
}

export default Home;