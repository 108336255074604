import React, { useEffect, useState } from "react";

import Select from "react-tailwindcss-select";

const SelectInput = ({
    index,
    initValue,
    size,
    options,
    changeHandler
}) => {

    const [selectedValue, setSelectedValue] = useState();

    useEffect(() => {
        setSelectedValue(initValue ? { value: initValue, label: initValue } : { value: "", label: "" });
    }, [initValue, index])

    return (
        <div className={`${size == "sm" ? 'w-[180px] h-[40px] px-[15px] py-[7.5px]' : 'w-[300px] md:w-[350px] h-[70px] px-[30px] py-[20px]'} flex justify-center items-center bg-light_grey rounded-[5px]`}>
            <Select
                classNames={{
                    menuButton: ({ }) => (
                        `flex text-[16px] ${selectedValue ? "text-dark_grey" : "text-[#A1A5B7]"} rounded-[5px] transition-all duration-300 bg-light_grey focus:outline-none overflow-hidden`
                    ),
                    menu: `absolute z-10 ${size == "sm" ? 'w-[180px]' : 'w-[300px] md:w-[350px]'} bg-light_grey rounded-[5px] py-1 ${size == "sm" ? "-ml-[15px]" : "-ml-[30px]"} ${size == "sm" ? "mt-[10px]" : "mt-[20px]"}  text-[16px] text-dark_grey`,
                    listItem: ({ isSelected }) => (
                        `block transition duration-200 px-[30px] py-2 cursor-pointer select-none truncate rounded ${isSelected
                            ? `bg-[#A1A5B7]`
                            : `hover:bg-[#A1A5B7]`
                        }`
                    )
                }}
                value={selectedValue}
                onChange={(value) => { setSelectedValue(value); changeHandler(value) }}
                options={options}
            />
        </div>
    );
}

export default SelectInput;