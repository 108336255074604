import React, { useEffect, useState } from "react";

const Input = ({
    initValue,
    type,
    size,
    placeholder,
    changeHandler
}) => {

    const [value, setValue] = useState();

    useEffect(() => {
        setValue(initValue);
    }, [initValue])

    return (
        <input value={value} type={type != "code" ? type : "password"} onChange={(e) => { setValue(type == "code" ? e.target.value[0] : e.target.value); changeHandler(type == "code" ? e.target.value[0] : e.target.value); }} placeholder={placeholder} className={`${size == "xs" ? 'w-[40px]' : (size == "sm" ? 'w-[140px]' : (size == "md" ? 'w-[300px]' : 'w-full'))} h-[40px] bg-light_grey rounded-[5px] px-[15px] py-[7.5px] focus:outline-none`} />
    );
}

export default Input;