import React, { useEffect, useState, useContext } from 'react';

import { useNavigate } from 'react-router';

import { UserContext } from "../../context/user-context";
import ContactUs from '../ContactUs/ContactUs';

import { messageToAdmin } from "../../utils/restService";

import { toast } from "react-toastify";

const Footer = () => {

    const navigate = useNavigate();

    const { userInfo, isLogin, signup, login } = useContext(UserContext);

    const [modal, setModal] = useState("");

    const onSubmit = async (email, title, message) => {
        
        if(!email) {
            toast.error("Input E-Mail!");
        }
        if(!title) {
            toast.error("Input Title!");
        }
        if(!message) {
            toast.error("Input Message!");
        }

        const res = await messageToAdmin(email, title, message);
        if (res.result) {
            toast.success("Sent message successfully!");
            setModal("");
        } else {
            toast.error(res.error);
        }
    }

    return (
        <div>
            <div className='font-mulish relative w-full h-auto md:h-[320px] flex justify-center bg-gradient-to-b from-[#14213D] to-[#0E2439]'>
                <div className="max-w-[1200px] w-full h-full px-[20px] py-[40px] flex flex-col md:flex-row gap-[20px] justify-start md:justify-between z-10">
                    <div className='flex flex-col gap-[5px]'>
                        <div className="flex gap-[5px] items-center">
                            <img className="w-[39px] h-[34px] object-contain" src="/images/icon.png" alt="icon" />
                            <img className="w-[73px] h-[9px] object-contain" src="/images/icon_text_light.png" alt="icon_text_light" />
                        </div>
                        <span className="max-w-[227px] text-[12px] text-[#CCC] font-[400]">Dein Spezialist für Unternehmens- und Immobilienfinanzierung</span>
                    </div>
                    <div className='flex gap-[60px]'>
                        <div className='flex flex-col gap-[10px]'>
                            <span onClick={(e) => { navigate("/products") }} className='text-[14px] text-white font-[700] cursor-pointer'>Los legen</span>
                        </div>
                        <div className='flex flex-col gap-[10px]'>
                            <span onClick={(e) => { !isLogin && login() }} className='text-[14px] text-white font-[700] cursor-pointer'>Login</span>
                            <span onClick={(e) => { signup() }} className='text-[14px] text-white font-[700] cursor-pointer'>Registrierung</span>
                        </div>
                        <div className='flex flex-col gap-[20px]'>

                            <div className='flex flex-col gap-[10px]'>
                                <div onClick={(e) => { setModal("contactus") }} className='flex gap-[5px] items-center cursor-pointer'>
                                    <span className='text-[12px] text-white font-[200]'>Kontaktiere uns</span>
                                </div>
                                <a href="/agb"><div className='flex gap-[5px] items-center'>
                                    <span className='text-[12px] text-white font-[200]'>AGB</span>
                                </div></a>
                                <a href="/datenschutz"><div className='flex gap-[5px] items-center'>
                                    <span className='text-[12px] text-white font-[200]'>Datenschutzerklärung</span>
                                </div></a>
                                <a href="https://www.linkedin.com/company/dki-schweiz"><div className='flex gap-[5px] items-center'>
                                    <span className='text-[12px] text-white font-[200]'>LinkedIn</span>
                                </div></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='absolute bottom-0 left-0 w-full h-[30px] flex justify-center items-center bg-[#173652]'>
                    <span className='text-[12px] text-[#CCC] font-[400]'>© Copyright 2024 - DKI Siwtzerland</span>
                </div>
            </div>
            {modal == "contactus" && <div className={`fixed top-0 left-0 w-[100vw] h-[100vh] ${modal === "" ? "hidden" : "flex"} justify-center items-center bg-[#00000030] p-[20px] z-10`}>
                <div
                    className="fixed inset-0 transition-opacity"
                    onClick={() => { setModal(""); }}
                />
                <ContactUs onSubmit={onSubmit}/>
            </div>
            }
        </div>
    );
};

export default Footer;