import { useEffect, useState } from "react"

function getStorageValue(key, defaultValue) {
    let saved
    if (typeof localStorage !== "undefined") {
        saved = localStorage.getItem(key)
    }

    let initial = defaultValue
    if (saved !== undefined && saved !== null) {
        try {
            initial = JSON.parse(saved)
        } catch (e) {
            console.log(`Failed to parse localStorage for key: ${key}`)
        }
    }

    return initial
}

export const useLocalStorage = (
    key,
    defaultValue,
) => {
    const [value, setValue] = useState()
    const [initialized, setIsInitialized] = useState(false)

    useEffect(() => {
        if (value !== undefined) {
            localStorage.setItem(key, JSON.stringify(value))
        }
    }, [key, value])

    const init = () => {
        setValue(getStorageValue(key, defaultValue))
        setIsInitialized(true)
    }

    const clear = () => {
        localStorage.removeItem(key)
        setValue(undefined)
    }

    return [value, setValue, clear, initialized, init]
}
