import React, { useEffect, useState } from "react";

import VerificationInput from "react-verification-input";

const Verify = ({
    verify_mail,
    onVerify,
    onResend
}) => {

    return (
        <div className="font-poppins w-full md:w-[420px] flex flex-col gap-[45px] bg-white rounded-[10px] px-0 md:px-[60px] py-[50px] z-10">
            <div className="flex flex-col gap-[25px] items-center">
                <div className="flex gap-[10px] items-center">
                    <img className="w-[61px] h-[54px] object-contain" src="/images/icon.png" alt="icon" />
                    <img className="w-[114px] h-[14px] object-contain" src="/images/icon_text_dark.png" alt="icon_text_dark" />
                </div>
                <span className="text-[24px] text-dark_grey font-[700]">Verifizierung</span>
            </div>
            <div className="flex flex-col gap-[30px] items-center">
                <VerificationInput length={5} validChars='0-9' onComplete={(code) => { onVerify(verify_mail, code) }} />
                <div className="flex flex-row gap-[10px] justify-between items-center">
                    <span className="text-[14px] text-dark_grey">Code nicht erhalten?</span>
                    <span onClick={(e) => { onResend(verify_mail) }} className="text-[16px] text-light_blue font-[500] cursor-pointer">Code erneut senden</span>
                </div>
            </div>
        </div>
    );
}

export default Verify;