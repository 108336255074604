import React, { useEffect, useState, useContext } from "react";

import { SubmitContext } from "../../context/submit-context";
import { LoadingContext } from "../../context/loading-context";

import Button from "../Basic/Button";
import TextArea from "../Basic/TextArea";
import DateInput from "../Basic/DateInput";
import SelectInput from "../Basic/SelectInput";

const Question = ({
    index,
    initValue,
    questions_data,
    setStep,
    user_id,
    product_id
}) => {

    const { answers, updateAnswers } = useContext(SubmitContext);
    const { setIsLoading } = useContext(LoadingContext);

    const [answer, setAnswer] = useState("");

    useEffect(() => {
        setAnswer(questions_data[index].type == "Select One" ? { value: initValue, label: initValue } : (questions_data[index].type == "Date Select" ? { startDate: initValue, endDate: initValue } : initValue));
    }, [initValue, index])

    return (
        <div className="w-full h-full flex flex-col justify-between rounded-[20px] bg-white px-[20px] md:px-[90px] py-[20px] md:py-[50px]">
            <div className="flex flex-col gap-[15px]">
                <span className="font-mulish text-[36px] text-dark_grey font-[700] text-center md:text-start">Frage {index + 1} / {questions_data.length}</span>
                <div className="flex flex-col gap-[40px]">
                    {/* <span className="font-mulish text-[20px] text-dark_grey font-[400]">{questions_data[index].question}</span> */}
                    <p className="font-mulish text-[20px] text-dark_grey font-[400]" dangerouslySetInnerHTML={{__html: questions_data[index].question?.replace(/\n/g, "<br />")}} />
                    <div className="flex flex-col gap-[10px] pb-[20px]">
                        <span className="font-poppins text-[16px] text-dark_grey font-[500]">Deine Antwort</span>
                        {questions_data[index].type == "Text Input" ?
                            <TextArea index={index} initValue={initValue} size="lg" placeholder="Please write your answer here." changeHandler={(value) => setAnswer(value)} /> :
                            (questions_data[index].type == "Date Select" ?
                                <DateInput index={index} initValue={initValue} size="md" changeHandler={(date) => setAnswer(date)} /> :
                                <SelectInput index={index} initValue={initValue} size="md" options={JSON.parse(questions_data[index].options).map((option) => { return { value: option, label: option } })} changeHandler={(option) => setAnswer(option)} />
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="flex gap-[10px] justify-between">
                <Button
                    disabled={index == 0}
                    mode="light"
                    size="sm"
                    label="Vorherige"
                    clickHandler={async (e) => {
                        setIsLoading(true);
                        await updateAnswers(
                            index,
                            questions_data[index].question,
                            questions_data[index].type == "Select One" ? answer.value : (questions_data[index].type == "Date Select" ? answer.startDate : answer),
                            user_id,
                            product_id
                        );
                        setIsLoading(false);
                        setStep(index - 1);
                    }} />
                <Button
                    disabled={
                        (questions_data[index].type == "Text Input" && answer == undefined) ||
                        (questions_data[index].type == "Select One" && answer?.value == undefined) ||
                        (questions_data[index].type == "Date Select" && answer?.startDate == undefined)
                    }
                    mode="dark"
                    size="sm"
                    label="Nächste"
                    clickHandler={(e) => {
                        updateAnswers(
                            index,
                            questions_data[index].question,
                            questions_data[index].type == "Select One" ? answer.value : (questions_data[index].type == "Date Select" ? answer.startDate : answer),
                            user_id,
                            product_id
                        );
                        setStep(index + 1);
                    }} />
            </div>
        </div>
    );
}

export default Question;