import React, { useEffect, useState } from "react";

const PrivacyPolicy = () => {

    return (
        <div className="font-poppins w-full h-auto md:h-[100vh] min-h-[100vh] flex flex-col items-center bg-back pt-[225px] md:pt-[180px] pb-[60px] overflow-auto">
            <div className="max-w-[1400px] w-full h-full flex flex-col md:flex-row gap-[20px] justify-center items-center px-[20px]">
                <iframe className="w-full h-full" id="pdf-iframe" src={`${process.env.REACT_APP_SERVER_URL}/documents/privacypolicy.pdf`} frameborder="0"></iframe>
            </div>
        </div>
    );
}

export default PrivacyPolicy;