import React, { useEffect, useState } from "react";

import Button from "../Basic/Button";
import UploadButton from "../Basic/UploadButton";
import TextArea from "../Basic/TextArea";
import Input from "../Basic/Input";
import { toast } from "react-toastify";

const AddProduct = ({
    onAddProduct
}) => {

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [icon, setIcon] = useState();
    const [iconName, setIconName] = useState();

    const onSave = () => {
        if(!name || !description || !iconName) {
            toast.error("Pleaes input fields!");
        } else {
            onAddProduct(name, description, icon)
        }
    }

    return (
        <div className="font-poppins w-full md:w-[420px] flex flex-col gap-[50px] bg-white rounded-[10px] px-0 md:px-[30px] py-[50px] z-10">
            <div className="flex flex-col gap-[25px] items-center">
                <span className="text-[24px] text-dark_grey font-[700]">Produkt hinzufügen</span>
            </div>
            <div className="flex flex-col gap-[40px] items-center">
                <div className="w-full flex flex-col gap-[30px]">
                    <div className="flex gap-[20px] justify-between items-center">
                        <span className="text-[16px] text-dark_grey font-[500]">Name:</span>
                        <Input initValue={name} size="lg" placeholder="Produktname" changeHandler={(value) => { setName(value) }} />
                    </div>
                    <TextArea initValue={description} size="lg" placeholder="Produktbeschreibung" changeHandler={(value) => { setDescription(value) }} />
                    <div className="flex gap-[20px] items-center">
                        <span className="text-[16px] text-dark_grey font-[500]">Symbol:</span>
                        <div className="flex gap-[10px] items-center">
                            <UploadButton clickHandler={(file) => { setIcon(file); setIconName(file.name) }} />
                            {iconName && <span className="w-[250px] text-[14px] text-[#F52323] font-[400] overflow-hidden truncate">{iconName}</span>}
                        </div>
                    </div>
                </div>
                <Button mode="light" size="sm" label="Speichern" clickHandler={(e) => { onSave() }} />
            </div>
        </div>
    );
}

export default AddProduct;