import React, { useEffect, useState } from "react";

import Input from "../Basic/Input";
import Button from "../Basic/Button";

const Login = ({
    onLogin,
    onResetPassword
}) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    return (
        <div className="font-poppins w-full md:w-[420px] flex flex-col gap-[45px] bg-white rounded-[10px] px-0 md:px-[60px] py-[50px] z-10">
            <div className="flex flex-col gap-[25px] items-center">
                <div className="flex gap-[10px] items-center">
                    <img className="w-[61px] h-[54px] object-contain" src="/images/icon.png" alt="icon" />
                    <img className="w-[114px] h-[14px] object-contain" src="/images/icon_text_dark.png" alt="icon_text_dark" />
                </div>
                <span className="text-[24px] text-dark_grey font-[700]">Login</span>
            </div>
            <form className="flex flex-col gap-[30px] items-center" onSubmit={(e) => { e.preventDefault(); onLogin(email, password) }}>
                <div className="flex flex-col gap-[10px]">
                    <div className="flex flex-col gap-[15px]">
                        <div className="flex flex-col gap-[5px]">
                            <span className="text-[16px] text-dark_grey font-[500]">E-Mail</span>
                            <Input size="md" placeholder="" changeHandler={(value) => { setEmail(value) }} />
                        </div>
                        <div className="flex flex-col gap-[5px]">
                            <span className="text-[16px] text-dark_grey font-[500]">Passwort</span>
                            <Input size="md" type="password" placeholder="Passwort" changeHandler={(value) => { setPassword(value) }} />
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <div onClick={(e) => { onResetPassword(email) }} className="text-[12px] text-light_blue font-[500] cursor-pointer">Passwort vergessen?</div>
                    </div>
                </div>
                <Button mode="light" size="sm" label="Log In" type="submit" />
            </form>
        </div>
    );
}

export default Login;