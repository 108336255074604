import React, { useEffect, useState, useContext } from "react";

import { UserContext } from "../../context/user-context";

import { useNavigate } from "react-router";

const ProductItem = ({
    id,
    name,
    icon,
    description
}) => {

    const { isLogin, login } = useContext(UserContext);

    const navigate = useNavigate();

    return (
        <button onClick={(e) => { if (isLogin) { navigate("/uploadinfo", { state: { product_id: id } }) } else { login(); } }} className="w-[216px] flex flex-col gap-[10px] items-center p-[10px] bg-white rounded-[10px]">
            <div className="h-[70px] flex justify-center pt-[9px] overflow-hidden">
                <span className="text-dark_grey text-[16px] font-[500]">{name}</span>
            </div>
            <div className="h-[75px] flex justify-center">
                <img className="h-full object-contain" src={`${process.env.REACT_APP_SERVER_URL}/${icon}`} alt="product_icon" />
            </div>
            <div className="h-[75px] flex justify-center pt-[7px] overflow-hidden">
                <span className="text-dark_grey text-[14px] font-[400]">{description}</span>
            </div>
        </button>
    );
}

export default ProductItem;