import React, { useEffect, useState, useContext } from "react";

import { useNavigate, useLocation } from "react-router";

import { LoadingContext } from "../context/loading-context";
import { UserContext } from "../context/user-context";
import { SubmitContext } from "../context/submit-context";

import Question from "../components/UploadInfo/Question";
import UploadFiles from "../components/UploadInfo/UploadFiles";
import { getAnswers, getQuestionsByProduct, uploadInformation } from "../utils/restService";

import { toast } from "react-toastify";

const UploadInfo = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const { setIsLoading } = useContext(LoadingContext);
    const { userInfo } = useContext(UserContext);
    const { answers, files, updateAnswers, updateFiles, resetSubmitData } = useContext(SubmitContext);

    const [step, setStep] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [documentInfo, setDocumentInfo] = useState("");

    const fetchInitialData = async () => {
        setIsLoading(true);
        const product_id = location.state.product_id;
        if (!location.state.reset_flag) {
            resetSubmitData({}, {});
            // await checkAnswerExist(product_id);
        }
        await fetchQuestions(product_id);
        setIsLoading(false);
    }

    const fetchQuestions = async (product_id) => {
        const res = await getQuestionsByProduct(product_id);
        if (!res.result) {
            toast.error(res.error);
            return;
        }

        setQuestions(res.data.filter((item) => item.type != "Upload Document").sort((a, b) => a.order - b.order));
        setDocumentInfo(res.data.filter((item) => item.type == "Upload Document").lentgh == 0 ? "" : res.data.filter((item) => item.type == "Upload Document")[0]);
    }

    const checkAnswerExist = async (product_id) => {
        const res = await getAnswers();
        if (!res.result) {
            toast.error(res.error);
            return;
        }

        res.data.map((answer) => {
            if (answer.user_id == userInfo._id && answer.product_id == product_id) {
                toast.warn("You've already answered! Just head to the dashboard and click edit to make changes or add more.");
                navigate("/products");
            }
        })

    }

    const submitInformation = async () => {
        setIsLoading(true);
        let submit_data = {
            user_id: userInfo?._id,
            product_id: location.state.product_id,
            answers: JSON.stringify(answers),
            files: files
        };

        console.log("submitInformation", submit_data);
        const res = await uploadInformation(submit_data);

        if (res.result) {
            toast.success("Upload Information successfully!");
            navigate("/products");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchInitialData();
    }, [])

    useEffect(() => {
        console.log("answers", answers);
    }, [answers])

    return (
        <div className="font-poppins w-full h-auto md:h-[100vh] min-h-[100vh] flex flex-col items-center bg-back pt-[225px] md:pt-[180px] pb-[60px] overflow-auto">
            <div className="max-w-[1400px] w-full h-full flex justify-center items-center px-[20px]">
                {step < questions.length && <Question index={step} initValue={answers[step]?.answer} questions_data={questions} setStep={setStep} user_id={userInfo?._id} product_id={location.state.product_id} />}
                {questions.length != 0 && step == questions.length && <UploadFiles index={step} questions_data={questions} setStep={setStep} documentInfo={documentInfo} submitInformation={submitInformation} user_id={userInfo?._id} product_id={location.state.product_id} />}
            </div>
        </div>
    );
}

export default UploadInfo;