import React, { useEffect, useState, useContext } from "react";

import { LoadingContext } from "../context/loading-context";

import TabItem from "../components/Basic/TabItem";
import UserAnswers from "../components/UserDashboard/UserAnswers";

const UserDashboard = () => {

    const { setIsLoading } = useContext(LoadingContext);

    const [selectedTab, setSelectedTab] = useState("Antworten");

    return (
        <div className="font-poppins w-full h-auto md:h-[100vh] min-h-[100vh] flex flex-col items-center bg-back pt-[225px] md:pt-[180px] pb-[60px] overflow-auto">
            <div className="max-w-[1400px] w-full h-full flex flex-col md:flex-row gap-[20px] justify-center items-center px-[20px]">
                {selectedTab == "Antworten" && <UserAnswers setIsLoading={setIsLoading} />}
            </div>
        </div>
    );
}

export default UserDashboard;