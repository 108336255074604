import React, { useEffect, useState } from "react";

const Button = ({
    disabled,
    mode,
    size,
    label,
    clickHandler,
    type
}) => {
    return (
        <button disabled={disabled} type={type} onClick={(e) => { if (type != "submit") clickHandler(e) }} className={`${size == "sm" ? 'w-[150px]' : 'w-[200px]'} min-h-[40px] flex justify-center items-center ${disabled ? 'bg-dark_grey' : (mode == 'light' ? 'bg-light_blue' : 'bg-dark_blue')} rounded-[100px]`}>
            <span className="text-white text-[18px] font-[500] leading-[18px]">{label}</span>
        </button>
    );
}

export default Button;