import React, { useEffect, useState, useRef } from "react";

const PrintButton = ({
    size,
    clickHandler
}) => {

    return (
        (size == "sm" ? <button onClick={(e) => { clickHandler(e) }} className="w-[30px] h-[30px] flex justify-center items-center bg-[#E6E6E6] rounded-full p-[10px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_150_528)">
                    <path d="M2.5 8.5H11.5C11.7652 8.5 12.0196 8.60536 12.2071 8.79289C12.3946 8.98043 12.5 9.23478 12.5 9.5V13.5H1.5V9.5C1.5 9.23478 1.60536 8.98043 1.79289 8.79289C1.98043 8.60536 2.23478 8.5 2.5 8.5V8.5Z" stroke="#54586C" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1.5 9.5C1.23478 9.5 0.98043 9.39464 0.792893 9.20711C0.605357 9.01957 0.5 8.76522 0.5 8.5V6.5C0.5 6.23478 0.605357 5.98043 0.792893 5.79289C0.98043 5.60536 1.23478 5.5 1.5 5.5H12.5C12.7652 5.5 13.0196 5.60536 13.2071 5.79289C13.3946 5.98043 13.5 6.23478 13.5 6.5V8.5C13.5 8.76522 13.3946 9.01957 13.2071 9.20711C13.0196 9.39464 12.7652 9.5 12.5 9.5" stroke="#54586C" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M11 0.5H3V5.5H11V0.5Z" stroke="#54586C" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4 11H10" stroke="#54586C" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_150_528">
                        <rect width="14" height="14" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </button> :
            <button onClick={(e) => { clickHandler(e) }} className="w-[80px] h-[80px] flex flex-col justify-between items-center bg-[#E6E6E6] rounded-[6px] px-[20px] py-[15px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <g clip-path="url(#clip0_150_636)">
                        <path d="M5 17H23C23.5304 17 24.0391 17.2107 24.4142 17.5858C24.7893 17.9609 25 18.4696 25 19V27H3V19C3 18.4696 3.21071 17.9609 3.58579 17.5858C3.96086 17.2107 4.46957 17 5 17V17Z" stroke="#58658A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M3 19C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13C1 12.4696 1.21071 11.9609 1.58579 11.5858C1.96086 11.2107 2.46957 11 3 11H25C25.5304 11 26.0391 11.2107 26.4142 11.5858C26.7893 11.9609 27 12.4696 27 13V17C27 17.5304 26.7893 18.0391 26.4142 18.4142C26.0391 18.7893 25.5304 19 25 19" stroke="#58658A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22 1H6V11H22V1Z" stroke="#58658A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 22H20" stroke="#58658A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_150_636">
                            <rect width="28" height="28" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <span className="text-[14px] text-[#58658A] fonr-[400]">Drucken</span>
            </button>
        )
    );
}

export default PrintButton;