import React, { useEffect, useState, useRef } from "react";

const RemoveButton = ({
    clickHandler
}) => {

    return (
        <button onClick={(e) => { clickHandler(e) }} className="w-[30px] h-[30px] flex justify-center items-center bg-[#E6E6E6] rounded-full p-[10px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <g clip-path="url(#clip0_150_220)">
                    <path d="M1 3.5H13" stroke="#54586C" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M2.5 3.5H11.5V12.5C11.5 12.7652 11.3946 13.0196 11.2071 13.2071C11.0196 13.3946 10.7652 13.5 10.5 13.5H3.5C3.23478 13.5 2.98043 13.3946 2.79289 13.2071C2.60536 13.0196 2.5 12.7652 2.5 12.5V3.5Z" stroke="#54586C" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4.5 3.5V3C4.5 2.33696 4.76339 1.70107 5.23223 1.23223C5.70107 0.763392 6.33696 0.5 7 0.5C7.66304 0.5 8.29893 0.763392 8.76777 1.23223C9.23661 1.70107 9.5 2.33696 9.5 3V3.5" stroke="#54586C" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5.5 5.5V11" stroke="#54586C" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.5 5.5V11" stroke="#54586C" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_150_220">
                        <rect width="14" height="14" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </button>
    );
}

export default RemoveButton;