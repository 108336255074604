import React, { useEffect, useState, useRef, useContext } from "react";

import { LoadingContext } from "../../context/loading-context";

import { useReactToPrint } from 'react-to-print';

import PrintButton from "../Basic/PrintButton";
import ViewPdfPrint from "./ViewPdfPrint";
import ViewButton from "../Basic/ViewButton";

import { toast } from "react-toastify";
import { getQuestionsByProduct } from "../../utils/restService";

const ViewPrint = ({
    user_name,
    user_email,
    product_name,
    product_id,
    date,
    answers,
    files
}) => {

    const { setIsLoading } = useContext(LoadingContext);

    const [modal, setModal] = useState("");
    const [pdfSrc, setPdfSrc] = useState("");
    const [documentInfo, setDocumentInfo] = useState("");

    const printRef = useRef();

    const onPrint = useReactToPrint({ content: () => printRef.current });

    const fetchInitialData = async () => {
        setIsLoading(true);
        await fetchQuestions(product_id);
        setIsLoading(false);
    }

    const fetchQuestions = async (product_id) => {
        const res = await getQuestionsByProduct(product_id);
        if (!res.result) {
            toast.error(res.error);
            return;
        }

        console.log("fetchQuestions res:", res);

        setDocumentInfo(res.data.filter((item) => item.type == "Upload Document").lentgh == 0 ? "" : res.data.filter((item) => item.type == "Upload Document")[0]?.documents);
    }

    useEffect(() => {
        console.log("ViewPrint answers files", answers, files);
        fetchInitialData();
    }, [])

    return (
        <>
            <div className="font-mulish w-full md:w-[830px] h-[70vh] flex flex-col md:flex-row bg-white rounded-[5px] z-10 justify-between">
                <div className="relative w-full md:w-[570px] flex flex-col ">
                    <div className="absolute top-[20px] md:top-[50px] right-[20px] md:right-[50px] flex">
                        <PrintButton size="md" clickHandler={(e) => { onPrint() }} />
                    </div>
                    <div ref={printRef} className="flex flex-col gap-[25px] p-[10%] overflow-auto">
                        <div className="flex flex-col gap-[20px]">
                            <span className="text-[30px] text-dark_grey font-[700]">{user_name}</span>
                            <div className="flex flex-col gap-[10px]">
                                <span className="text-[16px] text-dark_grey font-[400]">{user_email}</span>
                                <span className="text-[16px] text-dark_grey font-[400]">{product_name}</span>
                                <span className="text-[16px] text-dark_grey font-[400]">{new Date(date).toLocaleDateString()}</span>
                            </div>
                        </div>
                        <div className="flex flex-col gap-[10px]">
                            {Object.keys(JSON.parse(answers)).map((key) => {
                                return (
                                    <div className="flex flex-col gap-[5px]">
                                        {/* <span className="text-[14px] text-dark_grey font-[700]">{JSON.parse(answers)[key].question}</span> */}
                                        {/* <span className="text-[12px] text-dark_grey font-[400]">{JSON.parse(answers)[key].answer}</span> */}
                                        <p className="text-[14px] text-dark_grey font-[700]" dangerouslySetInnerHTML={{ __html: JSON.parse(answers)[key].question?.replace(/\n/g, "<br />") }} />
                                        <p className="text-[12px] text-dark_grey font-[400]" dangerouslySetInnerHTML={{ __html: JSON.parse(answers)[key].answer?.replace(/\n/g, "<br />") }} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-[260px] flex flex-col gap-[2px] md:gap-[10px] bg-light_grey px-[20px] py-[20px] md:py-[50px] overflow-auto">
                    <span className="text-[16px] text-dark_grey font-[700]">Sonstige Unterlagen</span>
                    <div className="flex flex-col gap-[2px] md:gap-[10px]">
                        {documentInfo && JSON.parse(documentInfo).map((document) => {
                            return (
                                files && JSON.parse(files)[document.name] && JSON.parse(files)[document.name].map((item) => {
                                    return (
                                        <div className="flex justify-between items-center">
                                            <span className="text-[14px] text-dark_grey font-[400]">{document.name}</span>
                                            <ViewButton clickHandler={(e) => { setPdfSrc(item); setModal("viewpdfprint") }} />
                                        </div>
                                    )
                                })
                            )
                        })
                        }
                        {files && JSON.parse(files)["extra"] && JSON.parse(files)["extra"].map((item) => {
                            return (
                                <div className="flex justify-between items-center">
                                    <span className="text-[14px] text-dark_grey font-[400]">Extra</span>
                                    <ViewButton clickHandler={(e) => { setPdfSrc(item); setModal("viewpdfprint") }} />
                                </div>
                            )
                        })
                        }
                    </div>
                    {/* {files && Object.keys(JSON.parse(files)).map((key) => {
                        return (
                            <div className="flex justify-between items-center">
                                <span className="text-[14px] text-dark_grey font-[400]">{key}</span>
                                <ViewButton clickHandler={(e) => { setPdfSrc(JSON.parse(files)[key]); setModal("viewpdfprint") }} />
                            </div>
                        )
                    })} */}
                </div>
            </div>
            <div className={`fixed top-0 left-0 w-[100vw] h-[100vh] ${modal === "" ? "hidden" : "flex"} justify-center items-center bg-[#00000030] p-[20px] z-20`}>
                <div
                    className="fixed inset-0 transition-opacity"
                    onClick={() => {
                        setModal("");
                    }}
                />
                {modal === "viewpdfprint" ?
                    <ViewPdfPrint src={pdfSrc} /> :
                    <></>
                }
            </div>
        </>
    );
}

export default ViewPrint;