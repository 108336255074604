import React, { useEffect, useState } from "react";

import UploadButton from "./UploadButton";

const UploadFile = ({
    initValue,
    index,
    label,
    required,
    warn,
    setWarn,
    changeHandler
}) => {

    const [files, setFiles] = useState([]);
    const [isOver, setIsOver] = useState(false);

    useEffect(() => {
        console.log("UpdateFiles_initValue:", initValue);
        if(!initValue) {
            setFiles([]);
        } else {
            setFiles(initValue);
        }
    }, [initValue])

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsOver(true);
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsOver(false);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        setIsOver(false);

        if (e.dataTransfer.files.length != 0) {
            const tmp_files = [...files, e.dataTransfer.files[0]];
            changeHandler(tmp_files);
            setFiles(tmp_files);
            if (required)
                setWarn(false);
        }
    }

    return (
        <div className="font-mulish flex flex-col md:flex-row gap-[10px] md:gap-[20px] items-end md:items-center">
            <div
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className={`w-full md:w-auto flex gap-[10px] md:gap-[100px] justify-between md:justify-start items-center p-[3px] ${isOver ? 'border border-dashed rounded-[5px] border-dark_grey' : ""}`}>
                <div className="flex items-center">
                    <div className="w-[20px] h-[20px] flex justify-center items-center">
                        {files.length != 0 && <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <g clipPath="url(#clip0_150_176)">
                                <path d="M0.5 8.55005L3.23 12.06C3.32212 12.1797 3.44016 12.277 3.57525 12.3446C3.71034 12.4121 3.85898 12.4482 4.01 12.45C4.15859 12.4518 4.3057 12.4203 4.44063 12.3581C4.57555 12.2958 4.6949 12.2042 4.79 12.09L13.5 1.55005" stroke="#1B9818" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_150_176">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        }
                    </div>
                    <span className={`w-[205px] md:w-[400px] text-[20px] ${files.length != 0 ? "text-[#1B9818]" : "text-dark_grey"} ${files.length != 0 || warn ? "font-[700]" : "font-[400]"}`}>{required ? "*" : ""}{label}</span>
                </div>
                <UploadButton clickHandler={(file) => { changeHandler([...files, file]); setFiles([...files, file]); if (required) setWarn(false); }} />
            </div>
            {files && files.map((file, index) => {
                return (
                    <div className="flex gap-[5px] items-center">
                        <span className="max-w-[150px] text-[14px] text-[#8D8D8D] font-[400] text-end md:text-start overflow-hidden">{typeof(file) != 'object' ? file : file.name}</span>
                        <button onClick={(e) => { 
                            const tmp_files = [...files];
                            tmp_files.splice(index, 1);
                            changeHandler(tmp_files);
                            setFiles(tmp_files);
                        }} className="w-[30px] h-[30px] flex justify-center items-center bg-[#E6E6E6] rounded-full p-[10px]">
                            <span className="text-[10px] font-[500] text-[#54586C]">x</span>
                        </button>
                    </div>
                )
            })}
            {(files.length == 0 && warn) && <span className="w-[200px] text-[14px] text-[#F52323] font-[400] text-end md:text-start overflow-hidden">This file is required</span>}
        </div>
    );
}

export default UploadFile;