import React, { useEffect, useState } from "react";

import Input from "../Basic/Input";
import ContractItem from "./ContractItem";
import { deleteContract, getContracts } from "../../utils/restService";

import { toast } from "react-toastify";

const Contracts = ({
    setIsLoading
}) => {

    const [contracts, setContracts] = useState([]);
    const [searchWord, setSearchWord] = useState("");

    const fetchInitialData = async () => {
        setIsLoading(true);
        await fetchContracts();
        setIsLoading(false);
    }

    const fetchContracts = async () => {
        const res = await getContracts();
        if (res.result) {
            setContracts(res.data);
        } else {
            toast.error(res.error);
        }
    }

    const onDeleteContract = async (id) => {
        setIsLoading(true);
        const res = await deleteContract(id);
        if (res.result) {
            let tmp = [];
            contracts.map((contract) => {
                if (contract._id != id) {
                    tmp.push(contract);
                }
            })
            setContracts(tmp);
            toast.success("Delete contract successfully!");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchInitialData();
    }, [])

    return (
        <div className="w-full h-full flex flex-col gap-[20px] md:gap-[30px] items-center bg-white rounded-[20px] px-[20px] py-[40px]">
            <div className="w-full flex flex-col md:flex-row gap-[10px] md:gap-[40px]">
                <Input size="lg" placeholder="Suchen Sie nach Benutzern per E-Mail" changeHandler={(value) => { setSearchWord(value) }} />
            </div>
            <div className="w-full flex flex-col gap-[20px] overflow-auto">
                <div className="w-[900px] md:w-full flex gap-[20px] justify-start md:justify-between items-center px-[10px] py-[5px]">
                    <span className="w-[150px] text-[16px] text-dark_grey font-[500]">Email</span>
                    <span className="w-[150px] text-[16px] text-dark_grey font-[500]">Name</span>
                    <span className="w-[100px] text-[16px] text-dark_grey font-[500]">Adresse</span>
                    <span className="w-[100px] text-[16px] text-dark_grey font-[500]">PLZ</span>
                    <span className="w-[100px] text-[16px] text-dark_grey font-[500]">Ort</span>
                    <span className="w-[200px] text-[16px] text-dark_grey font-[500]">Datum</span>
                </div>
                {contracts.filter((contract_data) => contract_data.user_email.includes(searchWord)).map((contract_data, index) => {
                    return (
                        <ContractItem index={index} data={contract_data} deleteHandler={(e) => { onDeleteContract(contract_data._id); }} />
                    )
                })}
            </div>
        </div >
    );
}

export default Contracts;