import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";

import { SubmitContext } from "../../context/submit-context";
import { UserContext } from "../../context/user-context";

import ViewPrint from "./ViewPrint";
import EditButton from "../Basic/EditButton";
import RemoveButton from "../Basic/RemoveButton";
import ViewButton from "../Basic/ViewButton";
import { deleteAnswer } from "../../utils/restService";

const AnswerItem = ({
    index,
    user_name,
    user_email,
    product,
    date,
    answers,
    files,
    deleteHandler,
}) => {

    const { isLogin, login } = useContext(UserContext);
    const { resetSubmitData } = useContext(SubmitContext);

    const navigate = useNavigate();

    const [modal, setModal] = useState("");

    return (
        <>
            <div className="font-mulish w-[900px] md:w-full flex gap-[20px] justify-start md:justify-between items-center cursor-pointer px-[10px] py-[5px] rounded-[5px] hover:bg-light_grey">
                <span className="w-[150px] text-[16px] text-dark_grey font-[500]">{user_name}</span>
                <span className="w-[250px] text-[16px] text-dark_grey font-[500]">{user_email}</span>
                <span className="w-[100px] text-[16px] text-dark_grey font-[500]">{product ? product.name : ""}</span>
                <span className="w-[100px] text-[16px] text-dark_grey font-[500]">{new Date(date).toLocaleDateString()}</span>
                <div className="w-[200px] flex justify-between">
                    <span className="text-[16px] text-dark_grey font-[500]">{Object.keys(JSON.parse(files)).length} Datei(en)</span>
                    <div className="flex gap-[10px]">
                        {product && <EditButton clickHandler={(e) => { if (isLogin) { resetSubmitData(JSON.parse(answers), JSON.parse(files)); navigate("/uploadinfo", { state: { product_id: product._id, reset_flag: true } }) } else { login(); } }} />}
                        <RemoveButton clickHandler={(e) => { if (isLogin) { deleteHandler(e); } else { login(); } }} />
                        <ViewButton clickHandler={(e) => { setModal("viewprint") }} />
                    </div>
                </div>
            </div>
            <div className={`fixed top-0 left-0 w-[100vw] h-[100vh] ${modal === "" ? "hidden" : "flex"} justify-center items-center bg-[#00000030] p-[20px] z-10`}>
                <div
                    className="fixed inset-0 transition-opacity"
                    onClick={() => {
                        setModal("");
                    }}
                />
                {modal === "viewprint" ?
                    <ViewPrint user_name={user_name} user_email={user_email} product_name={product ? product.name : ""} product_id={product?._id} date={date} answers={answers} files={files} /> :
                    <></>
                }
            </div>
        </>
    );
}

export default AnswerItem;