import React, { useEffect, useState, useContext } from "react";

import { LoadingContext } from "../context/loading-context";

import TabItem from "../components/Basic/TabItem";
import Questions from "../components/Dashboard/Questions";
import Answers from "../components/Dashboard/Answers";
import Products from "../components/Dashboard/Products";
import Contracts from "../components/Dashboard/Contracts";

const Dashboard = () => {

    const { setIsLoading } = useContext(LoadingContext);

    const [selectedTab, setSelectedTab] = useState("Fragen");

    return (
        <div className="font-poppins w-full h-auto md:h-[100vh] min-h-[100vh] flex flex-col items-center bg-back pt-[225px] md:pt-[180px] pb-[60px] overflow-auto">
            <div className="max-w-[1400px] w-full h-full flex flex-col md:flex-row gap-[20px] justify-center items-center px-[20px]">
                <div className="w-full md:w-[230px] h-full flex flex-col gap-[10px] items-center bg-white rounded-[20px] px-[20px] py-[20px] md:py-[40px]">
                    <TabItem selected={selectedTab == "Fragen"} size="md" label="Fragen" setSelectedTab={setSelectedTab} />
                    <TabItem selected={selectedTab == "Antworten"} size="md" label="Antworten" setSelectedTab={setSelectedTab} />
                    <TabItem selected={selectedTab == "Produkte"} size="md" label="Produkte" setSelectedTab={setSelectedTab} />
                    <TabItem selected={selectedTab == "Vertragwesen"} size="md" label="Vertragwesen" setSelectedTab={setSelectedTab} />
                </div>
                {selectedTab == "Fragen" && <Questions setIsLoading={setIsLoading} />}
                {selectedTab == "Antworten" && <Answers setIsLoading={setIsLoading} />}
                {selectedTab == "Produkte" && <Products setIsLoading={setIsLoading} />}
                {selectedTab == "Vertragwesen" && <Contracts setIsLoading={setIsLoading} />}
            </div>
        </div>
    );
}

export default Dashboard;