import React, { useEffect, useState } from "react";
import EditButton from "../Basic/EditButton";
import RemoveButton from "../Basic/RemoveButton";
import UpButton from "../Basic/UpButton";
import DownButton from "../Basic/DownButton";

const QuestionItem = ({
    index,
    question,
    product,
    editHandler,
    deleteHandler,
    upHandler,
    downHandler,
    searchWord
}) => {
    return (
        <div className="font-mulish w-[900px] md:w-full flex gap-[20px] justify-start md:justify-between items-center px-[10px] py-[5px] rounded-[5px] hover:bg-light_grey">
            <span className="w-[500px] text-[16px] text-dark_grey font-[500] overflow-hidden">{question}</span>
            <div className="w-[400px] flex justify-between items-center overflow-hidden">
                <span className="text-[16px] text-dark_grey font-[500]">{product}</span>
                <div className="flex gap-[10px]">
                    <EditButton clickHandler={(e) => { editHandler(index, e) }} />
                    <RemoveButton clickHandler={(e) => { deleteHandler(index, e) }} />
                    {!searchWord && <UpButton clickHandler={(e) => { upHandler(index, e) }} />}
                    {!searchWord && <DownButton clickHandler={(e) => { downHandler(index, e) }} />}
                </div>
            </div>
        </div>
    );
}

export default QuestionItem;