import React, { createContext, useEffect, useState, useContext } from "react";

import { useLocalStorage } from "./useLocalStorage";
import Signup from "../components/Auth/Signup";
import Login from "../components/Auth/Login";

import { login, signup, resetPassword, verify, resend } from "../utils/restService";

import { toast } from "react-toastify";
import Verify from "../components/Auth/Verify";

function useUserContext() {
    const [isLogin, setIsLogin] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [verifyMail, setVerifyMail] = useState();
    const [modal, setModal] = useState("");

    const [
        savedUser,
        setSavedUser,
        clearSavedUser,
        savedUserInitialized,
        initializeSavedUser,
    ] = useLocalStorage("user-info", undefined);

    useEffect(() => {
        if (!savedUserInitialized) {
            initializeSavedUser();
        }
        if (userInfo) {
            setIsLogin(true);
            setSavedUser(userInfo);
        }
    }, [userInfo]);

    useEffect(() => {
        if (savedUser == undefined) {
            setIsLogin(false);
            return;
        }

        if (!isLogin) {
            setIsLogin(true);
            setUserInfo(savedUser);
        }
    }, [savedUser]);

    const signup = async () => {
        setModal("signup");
    }

    const login = async () => {
        setModal("login");
    };

    const logout = () => {
        setIsLogin(false);
        setUserInfo();
        clearSavedUser();
    };

    return {
        isLogin: isLogin,
        userInfo, userInfo,
        verifyMail: verifyMail,
        modal: modal,
        setUserInfo: setUserInfo,
        setVerifyMail: setVerifyMail,
        setModal: setModal,
        signup: signup,
        login: login,
        logout: logout
    };
}

export const UserContext = createContext();

export const UserProvider = (props) => {
    const userContext = useUserContext();

    const onSignup = async (first_name, last_name, email, password, confirm_password) => {
        if (!first_name) {
            toast.error("Input First Name!");
        } else if (!last_name) {
            toast.error("Input Last Name!");
        } else if (password == confirm_password) {
            const res = await signup(first_name, last_name, email, password);
            if (res.result) {
                userContext.setVerifyMail(email);
                userContext.setModal("verify");
            } else {
                toast.error(res.error);
            }
        } else {
            toast.error("Incorrect confirm password!");
        }
    }

    const onLogin = async (email, password) => {
        const res = await login(email, password);
        console.log("onLogin", res);
        if (res.result) {
            userContext.setModal("");
            userContext.setUserInfo(res.data);
            toast.success("Log in successful!");
        } else {
            toast.error(res.error);
        }
    }

    const onResetPassword = async (email) => {
        const res = await resetPassword(email);
        if (res.result) {
            userContext.setModal("");
            toast.success("A password reset link has been sent to your email. Please check your inbox and follow the instructions.");
        } else {
            toast.error(res.error);
        }
    }

    const onVerify = async (email, code) => {
        const res = await verify(email, code);
        console.log("onVerify", res);
        if (res.result) {
            userContext.setModal("");
            userContext.setUserInfo(res.data);
            toast.success("Verify successful!");
        } else {
            toast.error(res.error);
        }
    }

    const onResend = async (email) => {
        const res = await resend(email);
        console.log("onResend", res);
        if (res.result) {
            toast.success("Resend successful!");
        } else {
            toast.error(res.error);
        }
    }

    return (
        <div>
            <UserContext.Provider value={{ ...userContext }}>
                {props.children}
                <div className={`fixed top-0 left-0 w-[100vw] h-[100vh] ${userContext.modal === "" ? "hidden" : "flex"} justify-center items-center bg-[#00000030] p-[20px] z-10`}>
                    <div
                        className="fixed inset-0 transition-opacity"
                        onClick={() => {
                            userContext.setModal("");
                        }}
                    />
                    {userContext.modal === "signup" ?
                        <Signup onSignup={onSignup} /> :
                        <></>
                    }
                    {userContext.modal === "login" ?
                        <Login onLogin={onLogin} onResetPassword={onResetPassword} /> :
                        <></>
                    }
                    {userContext.modal === "verify" ?
                        <Verify verify_mail={userContext.verifyMail} onVerify={onVerify} onResend={onResend} /> :
                        <></>
                    }
                </div>
            </UserContext.Provider>
        </div>
    );
};
