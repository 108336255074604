import React, { useEffect, useState, useContext } from "react";

import { SubmitContext } from "../../context/submit-context";
import { LoadingContext } from "../../context/loading-context";

import Button from "../Basic/Button";
import UploadFile from "../Basic/UploadFile";
import UploadFileExtra from "../Basic/UploadFileExtra";

const UploadFiles = ({
    index,
    questions_data,
    setStep,
    documentInfo,
    submitInformation,
    user_id,
    product_id
}) => {

    const { files, updateFiles } = useContext(SubmitContext);
    const { setIsLoading } = useContext(LoadingContext);

    const [warns, setWarns] = useState({});

    const onSubmit = async () => {
        if (!documentInfo.documents) return;
        let tmp = { ...warns };
        let flag = true;
        JSON.parse(documentInfo.documents).map((document) => {
            if (document.required && !files[document.name]) {
                tmp[document.name] = true;
                flag = false;
            }
        })
        setWarns(tmp);

        if (flag) submitInformation(false);
    }

    return (
        <div className="w-full h-full flex flex-col justify-between rounded-[20px] bg-white px-[20px] md:px-[90px] py-[20px] md:py-[50px] overflow-auto">
            <div className="flex flex-col gap-[15px]">
                <span className="font-mulish text-[36px] text-dark_grey font-[700] text-center">Laden Sie Ihre Dateien hoch</span>
                {documentInfo && <div className="flex flex-col gap-[40px]">
                    {/* <span className="font-mulish text-[20px] text-dark_grey font-[400] text-center">Laden Sie einfach Ihr Dokument zu diesem Produkt hoch. Dies wird vom Support-Team überprüft.</span> */}
                    <p className="font-mulish text-[20px] text-dark_grey font-[400]" dangerouslySetInnerHTML={{ __html: documentInfo.question?.replace(/\n/g, "<br />") }} />
                    <div className="flex flex-col gap-[20px] pb-[20px]">
                        {documentInfo.documents && JSON.parse(documentInfo.documents).map((document) => {
                            return (
                                files[document.name] != "extra" && <UploadFile initValue={files[document.name]} index={index} label={document.name} required={document.required} warn={warns[document.name]} setWarn={(warn) => { let tmp = { ...warns }; tmp[document.name] = warn; setWarns(tmp); }}
                                    changeHandler={async (files) => { setIsLoading(true); await updateFiles(document.name, files, user_id, product_id); setIsLoading(false); }} />
                            )
                        })}
                        <UploadFileExtra initValue={files["extra"]} changeHandler={async (files) => { setIsLoading(true); await updateFiles("extra", files, user_id, product_id); setIsLoading(false); }} />
                    </div>
                </div>
                }
            </div>
            <div className="flex gap-[10px] justify-between">
                <Button disabled={index == 0} mode="light" size="sm" label="Vorherige" clickHandler={(e) => { setStep(index - 1) }} />
                <Button mode="dark" size="sm" label="Einreichen" clickHandler={(e) => onSubmit()} />
            </div>
        </div>
    );
}

export default UploadFiles;