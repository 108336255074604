
import axios from 'axios';

const api_url = process.env.REACT_APP_SERVER_API_URL;

export const signup = async (first_name, last_name, email, password) => {
    console.log("signup", first_name, last_name, email, password);
    const res = axios.post(`${api_url}/user/signup`, { first_name, last_name, email, password })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const login = async (email, password) => {
    console.log("login", email, password);
    const res = axios.post(`${api_url}/user/login`, { email, password })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const verify = async (email, code) => {
    console.log("verify", email, code);
    const res = axios.post(`${api_url}/user/verify`, { email, code })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const resend = async (email) => {
    console.log("resend", email);
    const res = axios.post(`${api_url}/user/resend`, { email })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const resetPassword = async (email) => {
    console.log("resetPassword", email);
    const res = axios.post(`${api_url}/user/reset_password`, { email })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const updateDetails = async (first_name, last_name, email) => {
    console.log("updateDetails", first_name, last_name, email);
    const res = axios.post(`${api_url}/user/update_details`, { first_name, last_name, email })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const changePassword = async (current_password, email, password) => {
    console.log("changePassword", current_password, email, password);
    const res = axios.post(`${api_url}/user/change_password`, { current_password, email, password })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const getProducts = async () => {
    console.log("getProducts");
    const res = axios.get(`${api_url}/product/get_products`, {})
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const getQuestionsByProduct = async (product_id) => {
    console.log("getQuestionsByProduct");
    const res = axios.post(`${api_url}/question/get_questions_by_product`, { product_id })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const updateAnswer = async (info) => {
    console.log("updateAnswer", info);

    try {
        const res = axios.post(`${api_url}/answer/update_answer`, { info })
            .then(response => { return response.data })
            .catch(error => { console.log(error) });
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const deleteAnswer = async (id) => {
    console.log("deleteAnswer", id);
    const res = axios.post(`${api_url}/answer/delete_answer`, { id })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const updateFile = async (info) => {
    console.log("updateFile", info);

    const label = info.new_file.label;
    const new_files = info.new_file.new_files;
    let uploaded_file = {};

    try {
        let new_files_name = [];

        for (let i = 0; i < new_files.length; i++) {
            if(typeof(new_files[i]) != 'object') {
                new_files_name.push(new_files[i]);
                continue;
            }
            const formdata = new FormData();
            formdata.append('document', new_files[i]);
            const upload_res = (await axios.post(`${api_url}/answer/upload_document`, formdata)).data;
            if (upload_res.result) {
                new_files_name.push(upload_res.data.name);
            } else {
                console.log("File upload error:", upload_res.error);
            }
        }

        uploaded_file[label] = new_files_name;

        const res = axios.post(`${api_url}/answer/update_file`, { info: { ...info, new_file: JSON.stringify(uploaded_file) } })
            .then(response => { return response.data })
            .catch(error => { console.log(error) });
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const uploadInformation = async (info) => {
    console.log("uploadInformation", info);

    const keys = Object.keys(info.files);
    let files = {};
    try {
        for (let i = 0; i < keys.length; i++) {
            let file_names = [];
            for (let j = 0; j < info.files[keys[i]].length; j++) {
                if(typeof(info.files[keys[i]][j]) != 'object') {
                    file_names.push(info.files[keys[i]][j]);
                    continue;
                }
                const formdata = new FormData();
                formdata.append('document', info.files[keys[i]][j]);
                const res = (await axios.post(`${api_url}/answer/upload_document`, formdata)).data;
                if (res.result) {
                    file_names.push(res.data.name);
                } else {
                    console.log("File upload error:", res.error);
                }
            }
            files[keys[i]] = file_names;
        }

        const res = axios.post(`${api_url}/answer/add_answer`, { info: { ...info, files: JSON.stringify(files) } })
            .then(response => { return response.data })
            .catch(error => { console.log(error) });
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const getQuestions = async () => {
    console.log("getQuestions");
    const res = axios.get(`${api_url}/question/get_questions`, {})
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const addQuestion = async (question, type, options, documents, product_id) => {
    console.log("addQuestion", question, type, options, documents, product_id);
    const res = axios.post(`${api_url}/question/add_question`, { question, type, options, documents, product_id })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const updateQuestion = async (id, question, type, options, documents, product_id) => {
    console.log("updateQuestion", id, question, type, options, documents, product_id);
    const res = axios.post(`${api_url}/question/update_question`, { id, question, type, options, documents, product_id })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const deleteQuestion = async (id) => {
    console.log("deleteQuestion", id);
    const res = axios.post(`${api_url}/question/delete_question`, { id })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const swapQuestion = async (id, is_up) => {
    console.log("swapQuestion", id, is_up);
    const res = axios.post(`${api_url}/question/swap_question`, { id, is_up })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const getAnswers = async () => {
    console.log("getAnswers");
    const res = axios.get(`${api_url}/answer/get_answers`, {})
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const addProduct = async (name, description, icon) => {
    console.log("addProduct", name, description, icon);
    let icon_url;
    const formdata = new FormData();
    formdata.append('product_image', icon);
    const res_file = (await axios.post(`${api_url}/product/upload_product_image`, formdata)).data;

    if (res_file.result) {
        icon_url = res_file.data.name;
    } else {
        console.log("File upload error:", res_file.error);
    }
    const res = axios.post(`${api_url}/product/add_product`, { name, description, icon_url })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const updateProduct = async (id, name, description, icon) => {
    console.log("updateProduct", id, name, description, icon);
    let icon_url;
    if (icon) {
        const formdata = new FormData();
        formdata.append('product_image', icon);
        const res_file = (await axios.post(`${api_url}/product/upload_product_image`, formdata)).data;

        if (res_file.result) {
            icon_url = res_file.data.name;
        } else {
            console.log("File upload error:", res_file.error);
        }
    }
    const res = axios.post(`${api_url}/product/update_product`, { id, name, description, icon_url })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const deleteProduct = async (id) => {
    console.log("deleteProduct", id);
    const res = axios.post(`${api_url}/product/delete_product`, { id })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const getContracts = async () => {
    console.log("getContracts");
    const res = axios.get(`${api_url}/contract/get_contracts`, {})
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const addContract = async (user_id, secondContract, name, address, zip, place, locationCustomer, datum, secondLastnameFirstname, secondStreetNo, secondZipPlace, secondBirthday, secondSubsequentProperty, secondOrtDatum, secondNameVorname, secondCommentAdviser) => {
    console.log("addContract", user_id, secondContract, name, address, zip, place, locationCustomer, datum, secondLastnameFirstname, secondStreetNo, secondZipPlace, secondBirthday, secondSubsequentProperty, secondOrtDatum, secondNameVorname, secondCommentAdviser);
    const res = axios.post(`${api_url}/contract/add_contract`, { user_id, secondContract, name, address, zip, place, locationCustomer, datum, secondLastnameFirstname, secondStreetNo, secondZipPlace, secondBirthday, secondSubsequentProperty, secondOrtDatum, secondNameVorname, secondCommentAdviser })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const deleteContract = async (id) => {
    console.log("deleteContract", id);
    const res = axios.post(`${api_url}/contract/delete_contract`, { id })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}

export const messageToAdmin = async (email, title, message) => {
    console.log("messageToAdmin", email, title, message);
    const res = axios.post(`${api_url}/user/messate_to_admin`, { email, title, message })
        .then(response => { return response.data })
        .catch(error => { console.log(error) });
    return res;
}