import React, { useEffect, useState } from "react";

import Input from "../Basic/Input";
import Button from "../Basic/Button";

const MyDetails = ({
    userInfo,
    onUpdateDetails
}) => {

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();

    useEffect(() => {
        console.log(userInfo);
        if(userInfo) {
            setFirstName(userInfo.first_name);
            setLastName(userInfo.last_name);
            setEmail(userInfo.email);
        }
    }, [userInfo])

    return (
        <div className="w-full md:w-[360px] lg:w-[560px] h-[380px] flex flex-col justify-between items-center md:items-start rounded-[10px] bg-white px-0 md:px-[30px] py-[30px]">
            <span className="text-[20px] text-dark_grey font-[700]">Meine Details</span>
            <div className="flex flex-col gap-[15px]">
                <div className="flex gap-[20px]">
                    <div className="flex flex-col gap-[5px]">
                        <span className="text-[16px] text-dark_grey font-[500]">Vorname</span>
                        <Input initValue={firstName} size="sm" placeholder="" changeHandler={(value) => { setFirstName(value) }} />
                    </div>
                    <div className="flex flex-col gap-[5px]">
                        <span className="text-[16px] text-dark_grey font-[500]">Nachname</span>
                        <Input initValue={lastName} size="sm" placeholder="" changeHandler={(value) => { setLastName(value) }} />
                    </div>
                </div>
                <div className="flex flex-col gap-[5px]">
                    <span className="text-[16px] text-dark_grey font-[500]">Email</span>
                    <Input initValue={email} size="md" placeholder="" changeHandler={(value) => { setEmail(value) }} />
                </div>
            </div>
            <Button mode="light" size="md" label="Details aktualisieren" clickHandler={(e) => { onUpdateDetails(firstName, lastName, email) }} />
        </div>
    );
}

export default MyDetails;