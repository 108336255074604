import React, { useEffect, useState, useRef } from "react";

const UploadButton = ({
    clickHandler
}) => {

    const fileRef = useRef();

    const handleFileOpen = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            clickHandler(e.target.files[0]);
        }
    }

    return (
        <>
            <input onChange={(e) => { handleFileOpen(e) }} className='hidden' ref={fileRef} type="file" multiple={false} />
            <button onClick={(e) => { fileRef.current.click() }} className="w-[30px] h-[30px] flex justify-center items-center bg-[#E6E6E6] rounded-full p-[10px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <g clipPath="url(#clip0_150_161)">
                        <path d="M0.357178 7.5V8.21429C0.357178 8.59317 0.507688 8.95653 0.775597 9.22444C1.04351 9.49235 1.40687 9.64286 1.78575 9.64286H8.21432C8.5932 9.64286 8.95656 9.49235 9.22447 9.22444C9.49238 8.95653 9.64289 8.59317 9.64289 8.21429V7.5" stroke="#54586C" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.85718 2.85718L5.00003 0.357178L7.14289 2.85718" stroke="#54586C" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5 0.357178V6.78575" stroke="#54586C" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_150_161">
                            <rect width="10" height="10" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button>
        </>
    );
}

export default UploadButton;