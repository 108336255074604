import React, { useEffect, useState } from "react";

import Input from "../Basic/Input";
import Button from "../Basic/Button";
import CheckBox from "../Basic/CheckBox";

const Signup = ({ onSignup }) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [confirmPolicy, setConfirmPolicy] = useState(false);

    return (
        <div className="font-poppins w-full md:w-[420px] flex flex-col gap-[45px] bg-white rounded-[10px] px-0 md:px-[60px] py-[50px] z-10">
            <div className="flex flex-col gap-[25px] items-center">
                <div className="flex gap-[10px] items-center">
                    <img className="w-[61px] h-[54px] object-contain" src="/images/icon.png" alt="icon" />
                    <img className="w-[114px] h-[14px] object-contain" src="/images/icon_text_dark.png" alt="icon_text_dark" />
                </div>
                <span className="text-[24px] text-dark_grey font-[700]">Registrierung</span>
            </div>
            <form className="flex flex-col gap-[30px] items-center" onSubmit={(e) => { e.preventDefault(); if(confirmPolicy) onSignup(firstName, lastName, email, password, confirmPassword) }}>
                <div className="flex flex-col gap-[10px]">
                    <div className="flex flex-col gap-[15px]">
                        <div className="flex gap-[20px]">
                            <div className="flex flex-col gap-[5px]">
                                <span className="text-[16px] text-dark_grey font-[500]">Vorname</span>
                                <Input size="sm" placeholder="" changeHandler={(value) => { setFirstName(value) }} />
                            </div>
                            <div className="flex flex-col gap-[5px]">
                                <span className="text-[16px] text-dark_grey font-[500]">Nachname</span>
                                <Input size="sm" placeholder="" changeHandler={(value) => { setLastName(value) }} />
                            </div>
                        </div>
                        <div className="flex flex-col gap-[5px]">
                            <span className="text-[16px] text-dark_grey font-[500]">E-Mail</span>
                            <Input size="md" placeholder="" changeHandler={(value) => { setEmail(value) }} />
                        </div>
                        <div className="flex flex-col gap-[5px]">
                            <span className="text-[16px] text-dark_grey font-[500]">Passwort</span>
                            <div className="flex flex-col gap-[10px]">
                                <Input size="md" type="password" placeholder="Passwort" changeHandler={(value) => { setPassword(value) }} />
                                <Input size="md" type="password" placeholder="Bestätige das Passwort" changeHandler={(value) => { setConfirmPassword(value) }} />
                            </div>
                        </div>
                        <div className="flex gap-[10px] items-center">
                            <CheckBox clickHandler={(e, checked) => { setConfirmPolicy(checked) }} />
                            <span className="text-[10px] text-dark_grey font-[400]">Akzeptieren <a href="/agb" className="text-light_blue underline">Geschäftsbedingungen</a> und <a href="/datenschutz" className="text-light_blue underline">Datenschutzrichtlinie</a>.</span>
                        </div>
                    </div>
                </div>
                <Button disabled={!confirmPolicy} mode="light" size="md" label="Benutzerkonto erstellen" type="submit" />
            </form>
        </div>
    );
}

export default Signup;