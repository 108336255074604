import React, { useEffect, useState } from "react";

import Button from "../Basic/Button";
import SelectInput from "../Basic/SelectInput";
import Input from "../Basic/Input";
import QuestionItem from "../Dashboard/QuestionItem";
import AddQuestion from "./AddQuestion";
import EditQuestion from "./EditQuestion";
import { getProducts, getQuestions, addQuestion, updateQuestion, deleteQuestion, swapQuestion } from "../../utils/restService";

import { toast } from "react-toastify";

const Questions = ({
    setIsLoading
}) => {

    const [modal, setModal] = useState("");

    const [questions, setQuestions] = useState([]);
    const [products, setProducts] = useState([]);

    const [filterOptions, setFilterOptions] = useState([]);
    const [filterOption, setFilterOption] = useState({});
    const [searchWord, setSearchWord] = useState("");

    const [currentQuestion, setCurrentQuestion] = useState(-1);

    const fetchInitialData = async () => {
        setIsLoading(true);
        await fetchQuestions();
        await fetchProducts();
        setIsLoading(false);
    }

    const fetchQuestions = async () => {
        const res = await getQuestions();
        if (res.result) {
            setQuestions(res.data);
        } else {
            toast.error(res.error);
        }
    }

    const fetchProducts = async () => {
        const res = await getProducts();
        if (res.result) {
            setProducts(res.data);
            let tmp = [];
            res.data.map((product) => {
                tmp.push({ value: product.name, label: product.name });
            })
            setFilterOptions(tmp);
            if (tmp.length != 0) setFilterOption(tmp[0]);
        } else {
            toast.error(res.error);
        }
    }

    const onAddQuestion = async (question, type, options, documents, product_id) => {
        setIsLoading(true);
        const res = await addQuestion(question, type, JSON.stringify(options), JSON.stringify(documents), product_id);
        if (res.result) {
            let tmp = [...questions, res.data];
            setQuestions(tmp);
            setModal("");
            toast.success("Add question successfully!");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    const onUpdateQuestion = async (id, question, type, options, documents, product_id) => {
        setIsLoading(true);
        const res = await updateQuestion(id, question, type, JSON.stringify(options), JSON.stringify(documents), product_id);
        if (res.result) {
            let tmp = [];
            questions.map((question) => {
                if (question._id == id) {
                    tmp.push(res.data);
                } else {
                    tmp.push(question);
                }
            })
            setQuestions(tmp);
            setModal("");
            toast.success("Update question successfully!");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    const onSwapQuestion = async (id, is_up) => {
        setIsLoading(true);
        const res = await swapQuestion(id, is_up);
        if (res.result) {
            setQuestions(res.data);
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    const onDeleteQuestion = async (id) => {
        setIsLoading(true);
        const res = await deleteQuestion(id);
        if (res.result) {
            let tmp = [];
            questions.map((question) => {
                if (question._id != id) {
                    tmp.push(question);
                }
            })
            setQuestions(tmp);
            setModal("");
            toast.success("Delete question successfully!");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchInitialData();
    }, [])

    return (
        <>
            <div className="w-full h-full flex flex-col gap-[20px] md:gap-[30px] items-center bg-white rounded-[20px] px-[20px] py-[40px]">
                <div className="w-full flex flex-col md:flex-row justify-start md:justify-between gap-[10px] md:gap-[20px]">
                    <Button disabled={false} mode="light" size="sm" label="+ HINZUFÜGEN" clickHandler={(e) => { setModal("addquestion") }} />
                    <div className="flex flex-col md:flex-row gap-[10px] md:gap-[20px]">
                        <div className="flex gap-[20px] items-center">
                            <span className="text-[16px] text-dark_grey font-[500]">Produkt:</span>
                            <SelectInput initValue={filterOption.value} size="sm" options={filterOptions} changeHandler={(option) => { setFilterOption(option) }} />
                        </div>
                        <div className="flex md:hidden gap-[20px] items-center">
                            <Input size="lg" placeholder="Suchfragen" changeHandler={(value) => { setSearchWord(value) }} />
                        </div>
                        <div className="hidden md:flex gap-[20px] items-center">
                            <Input size="md" placeholder="Suchfragen" changeHandler={(value) => { setSearchWord(value) }} />
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col gap-[20px] overflow-auto">
                    <div className="w-[900px] md:w-full flex gap-[20px] justify-start md:justify-between items-center px-[10px] py-[5px]">
                        <span className="w-[500px] text-[16px] text-dark_grey font-[500]">Frage</span>
                        <span className="w-[400px] text-[16px] text-dark_grey font-[500]">Produkt</span>
                    </div>
                    {questions.sort((a, b) => a.order - b.order).filter((question_data) => question_data.question.includes(searchWord) && (filterOption.value == (products.find((product) => product._id == question_data.product_id))?.name)).map((question_data, index) => {
                        return (
                            <QuestionItem
                                index={index}
                                question={question_data.question}
                                product={(products.find((product) => product._id == question_data.product_id))?.name}
                                editHandler={(index, e) => { setModal("editquestion"); setCurrentQuestion(questions.sort((a, b) => a.order - b.order).filter((question_data) => question_data.question.includes(searchWord) && (filterOption.value == (products.find((product) => product._id == question_data.product_id))?.name))[index]) }}
                                deleteHandler={(index, e) => { onDeleteQuestion(questions.sort((a, b) => a.order - b.order).filter((question_data) => question_data.question.includes(searchWord) && (filterOption.value == (products.find((product) => product._id == question_data.product_id))?.name))[index]._id) }}
                                upHandler={(index, e) => { if (index != 0) onSwapQuestion(questions.sort((a, b) => a.order - b.order).filter((question_data) => question_data.question.includes(searchWord) && (filterOption.value == (products.find((product) => product._id == question_data.product_id))?.name))[index]._id, true) }}
                                downHandler={(index, e) => { if (index != questions.filter((question_data) => (filterOption.value == (products.find((product) => product._id == question_data.product_id))?.name)).length - 1) onSwapQuestion(questions.sort((a, b) => a.order - b.order).filter((question_data) => question_data.question.includes(searchWord) && (filterOption.value == (products.find((product) => product._id == question_data.product_id))?.name))[index]._id, false) }}
                                searchWord={searchWord}
                            />
                        )
                    })}
                </div>
            </div>
            <div className={`fixed top-0 left-0 w-[100vw] h-[100vh] ${modal === "" ? "hidden" : "flex"} justify-center items-center bg-[#00000030] p-[20px]`}>
                <div
                    className="fixed inset-0 transition-opacity"
                    onClick={() => {
                        setModal("");
                    }}
                />
                {modal === "addquestion" ?
                    <AddQuestion products={products} filterOptions={filterOptions} onAddQuestion={onAddQuestion} /> :
                    <></>
                }
                {modal === "editquestion" ?
                    <EditQuestion products={products} currentQuestion={currentQuestion} filterOptions={filterOptions} onUpdateQuestion={onUpdateQuestion} /> :
                    <></>
                }
            </div>
        </>
    );
}

export default Questions;