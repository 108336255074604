import React, { useEffect, useState } from "react";

import ProductItem from './ProductItem';
import { getProducts } from "../../utils/restService";
import { toast } from "react-toastify";

const ProductPanel = ({
    setIsLoading
}) => {

    const [products, setProducts] = useState([]);

    const fetchProducts = async () => {
        setIsLoading(true);

        const res = await getProducts();
        if (!res.result) {
            toast.error(res.error);
            return;
        }

        setProducts(res.data);
        
        setIsLoading(false);
    }

    useEffect(() => {
        fetchProducts();
    }, [])

    return (
        <div className="w-full flex flex-col gap-[45px] justify-center items-center">
            <div className="flex flex-col gap-[10px] items-center">
                <span className="text-dark_grey text-[36px] font-[700]">Wähle ein Produkt</span>
                <span className="text-dark_grey text-[18px] font-[400] text-center">Bitte wähle ein Produkt für die notwendigen Informationen und die sichere Übermittlung von deinen Daten.</span>
            </div>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[25px]">
                {products.map((product) => {
                    return (
                        <div className="flex justify-center">
                            <ProductItem id={product._id} name={product.name} icon={product.icon} description={product.description} />
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default ProductPanel;