import React, { useEffect, useState, useContext } from "react";

import { UserContext } from "../../context/user-context";

import ViewContract from "./ViewContract";
import RemoveButton from "../Basic/RemoveButton";
import ViewButton from "../Basic/ViewButton";

const ContractItem = ({
    index,
    data,
    deleteHandler,
}) => {

    const { isLogin, login } = useContext(UserContext);

    const [modal, setModal] = useState("");

    return (
        <>
            <div className="font-mulish w-[900px] md:w-full flex gap-[20px] justify-start md:justify-between items-center cursor-pointer px-[10px] py-[5px] rounded-[5px] hover:bg-light_grey">
                <span className="w-[150px] text-[16px] text-dark_grey font-[500]">{data.user_email}</span>
                <span className="w-[150px] text-[16px] text-dark_grey font-[500]">{data.name}</span>
                <span className="w-[100px] text-[16px] text-dark_grey font-[500]">{data.address}</span>
                <span className="w-[100px] text-[16px] text-dark_grey font-[500]">{data.zip}</span>
                <span className="w-[100px] text-[16px] text-dark_grey font-[500]">{data.place}</span>
                <div className="w-[200px] flex justify-between">
                    <span className="text-[16px] text-dark_grey font-[500]">{data.date}</span>
                    <div className="flex gap-[10px]">
                        <RemoveButton clickHandler={(e) => { if (isLogin) { deleteHandler(e); } else { login(); } }} />
                        <ViewButton clickHandler={(e) => { setModal("viewcontract") }} />
                    </div>
                </div>
            </div>
            <div className={`fixed top-0 left-0 w-[100vw] h-[100vh] ${modal === "" ? "hidden" : "flex"} justify-center items-center bg-[#00000030] p-[20px] z-10`}>
                <div
                    className="fixed inset-0 transition-opacity"
                    onClick={() => {
                        setModal("");
                    }}
                />
                {modal === "viewcontract" ?
                    <ViewContract data={data} /> :
                    <></>
                }
            </div>
        </>
    );
}

export default ContractItem;