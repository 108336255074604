import React, { useEffect, useState } from "react";

import Input from "../Basic/Input";
import Button from "../Basic/Button";

const ChangePassword = ({
    onChangePassword
}) => {

    const [currentPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();

    return (
        <div className="w-full md:w-[360px] lg:w-[560px] h-[380px] flex flex-col justify-between items-center md:items-start rounded-[10px] bg-white px-0 md:px-[30px] py-[30px]">
            <span className="text-[20px] text-dark_grey font-[700]">Passwort ändern</span>
            <div className="flex flex-col gap-[15px]">
                <div className="flex flex-col gap-[5px]">
                    <span className="text-[16px] text-dark_grey font-[500]">Aktuelles Passwort</span>
                    <Input size="md" type="password" placeholder="" changeHandler={(value) => { setCurrentPassword(value) }} />
                </div>
                <div className="flex flex-col gap-[5px]">
                    <span className="text-[16px] text-dark_grey font-[500]">Neues Passwort</span>
                    <Input size="md" type="password" placeholder="Neues Passwort" changeHandler={(value) => { setNewPassword(value) }} />
                    <Input size="md" type="password" placeholder="Bestätige das Passwort" changeHandler={(value) => { setConfirmPassword(value) }} />
                </div>
            </div>
            <Button mode="light" size="md" label="Passwort ändern" clickHandler={(e) => { onChangePassword(currentPassword, newPassword, confirmPassword)}} />
        </div>
    );
}

export default ChangePassword;