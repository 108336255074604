import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { LoadingProvider } from "./context/loading-context";
import { UserProvider } from "./context/user-context";
import { SubmitProvider } from "./context/submit-context";

import Home from "./pages/Home";
import MyAccount from "./pages/MyAccount";
import Products from "./pages/Products";
import UploadInfo from "./pages/UploadInfo";
import Dashboard from "./pages/Dashboard";
import Contract from "./pages/Contract";
import NoPage from "./pages/NoPage";
import Layout from "./pages/Layout";
import Footer from "./components/Layout/Footer";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
import UserDashboard from "./pages/UserDashboard";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element=
            {
              <>
                <Home />
                <Footer />
              </>
            }
          />
          <Route path="/myaccount" element={<Layout />}>
            <Route index element={<MyAccount />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/products" element={<Layout />}>
            <Route index element={<Products />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/uploadinfo" element={<Layout />}>
            <Route index element={<UploadInfo />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/dashboard" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/user_dashboard" element={<Layout />}>
            <Route index element={<UserDashboard />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/contract" element={<Layout />}>
            <Route index element={<Contract />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/agb" element={<Layout />}>
            <Route index element={<TermsConditions />} />
            <Route path="*" element={<NoPage />} />
          </Route>
          <Route path="/datenschutz" element={<Layout />}>
            <Route index element={<PrivacyPolicy />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

ReactDOM.render(
  <LoadingProvider>
    <UserProvider>
      <SubmitProvider>
        <App />
      </SubmitProvider>
    </UserProvider>
  </LoadingProvider>
  , document.getElementById("root")
);
