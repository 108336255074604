import React, { useEffect, useState } from "react";
import EditButton from "../Basic/EditButton";
import RemoveButton from "../Basic/RemoveButton";

const ProductItem = ({
    index,
    name,
    description,
    icon,
    editHandler,
    deleteHandler
}) => {
    return (
        <div className="font-mulish w-[900px] md:w-full flex gap-[20px] justify-start md:justify-between items-center px-[10px] py-[5px] rounded-[5px] hover:bg-light_grey">
            <span className="w-[250px] text-[16px] text-dark_grey font-[500] overflow-hidden">{name}</span>
            <span className="w-[400px] text-[16px] text-dark_grey font-[500] overflow-hidden">{description}</span>
            <div className="w-[250px] flex justify-between items-center overflow-hidden">
                <img className="w-[25px] h-[25px] object-contain" src={`${process.env.REACT_APP_SERVER_URL}/${icon}`} alt={name} />
                <div className="flex gap-[15px]">
                    <EditButton clickHandler={(e) => { editHandler(index, e) }} />
                    <RemoveButton clickHandler={(e) => { deleteHandler(index, e) }} />
                </div>
            </div>
        </div>
    );
}

export default ProductItem;