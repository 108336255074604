import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { UserContext } from "../../context/user-context";

import Button from '../Basic/Button';

const Header = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const { userInfo, isLogin, signup, login, logout } = useContext(UserContext);

    const [path, setPath] = useState("Start");

    useEffect(() => {
        if(location.pathname == "/myaccount") {
            setPath("Start > Mein Konto");
        } else if(location.pathname == "/products") {
            setPath("Start > Produkte");
        } else if(location.pathname == "/uploadinfo") {
            setPath("Start > Upload Information");
        } else if(location.pathname == "/dashboard") {
            setPath("Dashboard");
        } else if(location.pathname == "/contract") {
            setPath("Vertragwesen");
        }
    }, [location])

    return (
        <div className='font-poppins fixed w-full flex flex-col z-10'>
            <div className='w-full h-auto md:h-[80px] flex flex-col items-center bg-white'>
                <div className="max-w-[1400px] w-full h-auto md:h-full px-[20px] py-[10px] md:py-0 flex flex-col md:flex-row gap-[10px] justify-start md:justify-between items-center justify-between">
                    <div className="flex gap-[80px] items-center">
                        <button onClick={(e) => { navigate("/") }} className="flex gap-[10px] items-center">
                            <img className="w-[61px] h-[54px] object-contain" src="/images/icon.png" alt="icon" />
                            <img className="w-[114px] h-[14px] object-contain" src="/images/icon_text_dark.png" alt="icon_text_dark" />
                        </button>
                        <div className="flex gap-[25px] items-center">
                            <button onClick={(e) => { navigate("/") }} className="text-[16px] text-dark_grey font-[500]">Start</button>
                            <button onClick={(e) => { navigate("/products") }} className="text-[16px] text-dark_grey font-[500]">Produkte</button>
                        </div>
                    </div>
                    {isLogin ? <div className="flex gap-[30px]">
                        {userInfo.role == "admin" && <button onClick={(e) => { navigate("/dashboard") }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Dashboard</button>}
                        {userInfo.role != "admin" && <button onClick={(e) => { navigate("/user_dashboard") }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Dashboard</button>}
                        <button onClick={(e) => { navigate("/contract") }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Vertragwesen</button>
                        <button onClick={(e) => { navigate("/myaccount") }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Mein Konto</button>
                        <button onClick={(e) => { logout(); navigate("/") }} className='text-[14px] md:text-[18px] text-light_blue font-[500] text-center underline'>Ausloggen</button>
                    </div> :
                        <div className="flex gap-[30px]">
                            <Button mode="light" size="sm" label="Registrierung" clickHandler={(e) => { signup(); }} />
                            <Button mode="dark" size="sm" label="Login" clickHandler={(e) => { login(); }} />
                        </div>
                    }
                </div>
            </div>
            <div className='w-full h-[40px] flex flex-col items-center bg-dark_blue'>
                <div className="max-w-[1400px] w-full h-full px-[20px] flex gap-[10px] items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
                        <g clipPath="url(#clip0_0_50)">
                            <path d="M12.4141 0.46875L12 0.105469L11.5859 0.46875L0.960938 9.84375L1.78906 10.7812L3.25 9.49219V19.375V20H3.875H20.125H20.75V19.375V9.49219L22.2109 10.7812L23.0391 9.84375L12.4141 0.46875ZM4.5 18.75V8.38672L12 1.76953L19.5 8.38672V18.75H15.125V11.875V11.25H14.5H9.5H8.875V11.875V18.75H4.5ZM10.125 18.75V12.5H13.875V18.75H10.125Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_0_50">
                                <rect width="22.5" height="20" fill="white" transform="translate(0.75)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className='flex gap-[5px] items-center'>
                        <span className='text-[14px] text-white font-[400]'>{path}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;