import React, { useEffect, useState } from "react";

import Button from "../Basic/Button";
import Input from "../Basic/Input";
import ProductItem from "./ProductItem";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import { getProducts, addProduct, updateProduct, deleteProduct } from "../../utils/restService";

import { toast } from "react-toastify";

const Products = ({
    setIsLoading
}) => {

    const [modal, setModal] = useState("");

    const [products, setProducts] = useState([]);

    const [searchWord, setSearchWord] = useState("");

    const [currentProduct, setCurrentProduct] = useState(-1);

    const fetchInitialData = async () => {
        setIsLoading(true);
        await fetchProducts();
        setIsLoading(false);
    }

    const fetchProducts = async () => {
        const res = await getProducts();
        if (res.result) {
            setProducts(res.data);
        } else {
            toast.error(res.error);
        }
    }

    const onAddProduct = async (name, description, icon) => {
        setIsLoading(true);
        const res = await addProduct(name, description, icon);
        if (res.result) {
            let tmp = [...products, res.data];
            setProducts(tmp);
            setModal("");
            toast.success("Add product successfully!");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    const onUpdateProduct = async (id, name, description, icon) => {
        setIsLoading(true);
        const res = await updateProduct(id, name, description, icon);
        if (res.result) {
            let tmp = [];
            products.map((product) => {
                if (product._id == id) {
                    tmp.push(res.data);
                } else {
                    tmp.push(product);
                }
            })
            setProducts(tmp);
            setModal("");
            toast.success("Update product successfully!");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    const onDeleteProduct = async (id) => {
        setIsLoading(true);
        const res = await deleteProduct(id);
        if (res.result) {
            let tmp = [];
            products.map((product) => {
                if (product._id != id) {
                    tmp.push(product);
                }
            })
            setProducts(tmp);
            setModal("");
            toast.success("Delete question successfully!");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchInitialData();
    }, [])

    return (
        <>
            <div className="w-full h-full flex flex-col gap-[20px] md:gap-[30px] items-center bg-white rounded-[20px] px-[20px] py-[40px]">
                <div className="w-full flex flex-col md:flex-row justify-start md:justify-between gap-[10px] md:gap-[20px]">
                    <Button disabled={false} mode="light" size="sm" label="+ HINZUFÜGEN" clickHandler={(e) => { setModal("addproduct") }} />
                    <div className="flex gap-[20px] items-center">
                        <Input size="md" placeholder="Produktsuche" changeHandler={(value) => { setSearchWord(value) }} />
                    </div>
                </div>
                <div className="w-full flex flex-col gap-[20px] overflow-auto">
                    <div className="w-[900px] md:w-full flex gap-[20px] justify-start md:justify-between items-center px-[10px] py-[5px]">
                        <span className="w-[250px] text-[16px] text-dark_grey font-[500]">Name</span>
                        <span className="w-[400px] text-[16px] text-dark_grey font-[500]">Beschreibung</span>
                        <span className="w-[250px] text-[16px] text-dark_grey font-[500]">Symbol</span>
                    </div>
                    {products.filter((product_data) => product_data.name.includes(searchWord)).map((product_data, index) => {
                        return (
                            <ProductItem index={index} name={product_data.name} description={product_data.description} icon={product_data.icon} editHandler={(index, e) => { setModal("editproduct"); setCurrentProduct(product_data) }} deleteHandler={(index, e) => { onDeleteProduct(product_data._id) }} />
                        )
                    })}
                </div>
            </div>
            <div className={`fixed top-0 left-0 w-[100vw] h-[100vh] ${modal === "" ? "hidden" : "flex"} justify-center items-center bg-[#00000030] p-[20px]`}>
                <div
                    className="fixed inset-0 transition-opacity"
                    onClick={() => {
                        setModal("");
                    }}
                />
                {modal === "addproduct" ?
                    <AddProduct onAddProduct={onAddProduct} /> :
                    <></>
                }
                {modal === "editproduct" ?
                    <EditProduct currentProduct={currentProduct} onUpdateProduct={onUpdateProduct} /> :
                    <></>
                }
            </div>
        </>
    );
}

export default Products;