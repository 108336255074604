import React, { useEffect, useState, useContext } from "react";

import { LoadingContext } from "../context/loading-context";

import ProductPanel from "../components/Products/ProductPanel";

const Products = () => {

    const { setIsLoading } = useContext(LoadingContext);

    return (
        <div className="font-poppins w-full h-full min-h-[100vh] flex flex-col justify-center items-center bg-back pt-[185px] md:pt-[140px] pb-[20px] overflow-auto">
            <div className="max-w-[1400px] w-full h-full flex px-[20px]">
                <ProductPanel setIsLoading={setIsLoading}/>
            </div>
        </div>
    );
}

export default Products;