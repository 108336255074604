import React, { useEffect, useState, useContext } from "react";

import { LoadingContext } from "../context/loading-context";
import { UserContext } from "../context/user-context";

import MyDetails from "../components/MyAccount/MyDetails";
import ChangePassword from "../components/MyAccount/ChangePassword";
import { changePassword, updateDetails } from "../utils/restService";
import { toast } from "react-toastify";

const MyAccount = () => {

    const { setIsLoading } = useContext(LoadingContext);
    const { userInfo, setUserInfo } = useContext(UserContext);

    const onUpdateDetails = async (first_name, last_name, email) => {

        if (!userInfo) {
            toast.error("Login to update details!");
            return;
        }

        setIsLoading(true);
        if (email == userInfo.email) {
            const res = await updateDetails(first_name, last_name, email);
            console.log(res);
            if (res.result) {
                setUserInfo(res.data);
                toast.success("Update details successful!");
            } else {
                toast.error(res.error);
            }
        } else {
            toast.error("Incorrect email!");
        }
        setIsLoading(false);
    }

    const onChangePassword = async (current_password, new_password, confirm_password) => {

        if (!userInfo) {
            toast.error("Login to change password!");
            return;
        }

        setIsLoading(true);
        if (new_password == confirm_password) {
            const res = await changePassword(current_password, userInfo.email, new_password);
            console.log(res);
            if (res.result) {
                setUserInfo(res.data);
                toast.success("Change password successful!");
            } else {
                toast.error(res.error);
            }
        } else {
            toast.error("Incorrect confirm password!");
        }
        setIsLoading(false);
    }

    return (
        <div className="font-poppins w-full h-auto md:h-[100vh] min-h-[100vh] flex flex-col items-center bg-back pt-[185px] md:pt-[140px] pb-[20px] overflow-auto">
            <div className="max-w-[1400px] w-full h-full flex flex-col md:flex-row gap-[20px] justify-center items-center md:items-start px-[20px]">
                <MyDetails userInfo={userInfo} onUpdateDetails={onUpdateDetails} />
                <ChangePassword onChangePassword={onChangePassword} />
            </div>
        </div>
    );
}

export default MyAccount;