import React, { useEffect, useState, useContext } from "react";

import { UserContext } from "../../context/user-context";

import SelectInput from "../Basic/SelectInput";
import Input from "../Basic/Input";
import UserAnswerItem from "./UserAnswerItem";
import { deleteAnswer, getAnswers, getProducts } from "../../utils/restService";

import { toast } from "react-toastify";

const Answers = ({
    setIsLoading
}) => {

    const { userInfo } = useContext(UserContext);

    const [answers, setAnswers] = useState([]);
    const [products, setProducts] = useState([]);

    const [searchWord, setSearchWord] = useState("");

    const fetchInitialData = async () => {
        setIsLoading(true);
        await fetchAnswers();
        await fetchProducts();
        setIsLoading(false);
    }

    const fetchAnswers = async () => {
        const res = await getAnswers();
        if (res.result) {
            setAnswers(res.data.filter((answer) => answer.user_email == userInfo?.email));
        } else {
            toast.error(res.error);
        }
    }

    const fetchProducts = async () => {
        const res = await getProducts();
        if (res.result) {
            setProducts(res.data);
        } else {
            toast.error(res.error);
        }
    }

    const onDeleteAnswer = async (id) => {
        setIsLoading(true);
        const res = await deleteAnswer(id);
        if (res.result) {
            let tmp = [];
            answers.map((answer) => {
                if (answer._id != id) {
                    tmp.push(answer);
                }
            })
            setAnswers(tmp);
            toast.success("Delete answer successfully!");
        } else {
            toast.error(res.error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchInitialData();
    }, [userInfo])

    return (
        <div className="w-full h-full flex flex-col gap-[20px] md:gap-[30px] items-center bg-white rounded-[20px] px-[20px] py-[40px]">
            {/* <div className="w-full flex flex-col md:flex-row gap-[10px] md:gap-[40px]">
                <Input size="lg" placeholder="Suchen Sie nach Benutzern per E-Mail" changeHandler={(value) => { setSearchWord(value) }} />
            </div> */}
            <div className="w-full flex flex-col gap-[20px] overflow-auto">
                <div className="w-[900px] md:w-full flex gap-[20px] justify-start md:justify-between items-center px-[10px] py-[5px]">
                    <span className="w-[150px] text-[16px] text-dark_grey font-[500]">Name</span>
                    <span className="w-[250px] text-[16px] text-dark_grey font-[500]">Email</span>
                    <span className="w-[100px] text-[16px] text-dark_grey font-[500]">Produkt</span>
                    <span className="w-[100px] text-[16px] text-dark_grey font-[500]">Datum</span>
                    <span className="w-[200px] text-[16px] text-dark_grey font-[500]">Datei</span>
                </div>
                {answers.filter((answer_data) => answer_data.user_email.includes(searchWord)).map((answer_data, index) => {
                    return (
                        <UserAnswerItem index={index} is_draft={answer_data.is_draft} user_name={answer_data.user_name} user_email={answer_data.user_email} product={products.find((product) => product.name == answer_data.product_name)} date={answer_data.date} answers={answer_data.answers} files={answer_data.files} deleteHandler={(e) => { onDeleteAnswer(answer_data._id); }} />
                    )
                })}
            </div>
        </div >
    );
}

export default Answers;