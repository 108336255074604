import React, { useEffect, useState } from "react";

import Button from "../Basic/Button";
import SelectInput from "../Basic/SelectInput";
import TextArea from "../Basic/TextArea";
import Input from "../Basic/Input";
import RemoveButton from "../Basic/RemoveButton";
import CheckBox from "../Basic/CheckBox";

const question_type_options = [
    { value: "Text Input", label: "Text Input" },
    { value: "Date Select", label: "Date Select" },
    { value: "Select One", label: "Select One" },
    { value: "Upload Document", label: "Upload Document" },
]

const EditQuestion = ({
    products,
    currentQuestion,
    filterOptions,
    onUpdateQuestion
}) => {

    const [product, setProduct] = useState(filterOptions[0]);
    const [question, setQuestion] = useState("");
    const [type, setType] = useState(question_type_options[0]);

    const [currentInputAnswer, setCurrentInputAnswer] = useState("");
    const [selectAnswers, setSelectAnswers] = useState([]);
    const [documents, setDocuments] = useState([]);

    const onAddAnswer = () => {
        if (currentInputAnswer) {
            let tmp = [...selectAnswers, currentInputAnswer];
            setSelectAnswers(tmp);
            setCurrentInputAnswer("");
        }
    }

    const onDelectAnswer = (index) => {
        console.log("delete");
        let tmp = [...selectAnswers];
        tmp.splice(index, 1);
        setSelectAnswers(tmp);
    }

    const onAddDocument = () => {
        if (currentInputAnswer) {
            let tmp = [...documents, { name: currentInputAnswer, required: false }];
            setDocuments(tmp);
            setCurrentInputAnswer("");
        }
    }

    const onDelectDocument = (index) => {
        let tmp = [...documents];
        tmp.splice(index, 1);
        setDocuments(tmp);
    }

    const onSave = () => {
        onUpdateQuestion(currentQuestion._id, question, type.value, selectAnswers, documents, products.find((productItem) => productItem.name == product.value)?._id)
    }

    useEffect(() => {

        if (currentQuestion) {
            const product_name = products.find((product) => product._id == currentQuestion.product_id)?.name;
            setProduct({ value: product_name, label: product_name });
            setQuestion(currentQuestion.question);
            setType({ value: currentQuestion.type, label: currentQuestion.type });
            setSelectAnswers(JSON.parse(currentQuestion.options));
            setDocuments(JSON.parse(currentQuestion.documents));
        }
    }, [currentQuestion])

    return (
        <div className="font-poppins w-full md:w-[420px] flex flex-col gap-[50px] bg-white rounded-[10px] px-0 md:px-[30px] py-[50px] z-10">
            <div className="flex flex-col gap-[25px] items-center">
                <span className="text-[24px] text-dark_grey font-[700]">Frage bearbeiten</span>
            </div>
            <div className="flex flex-col gap-[40px] items-center max-h-[70vh] overflow-auto">
                <div className="w-full flex flex-col gap-[30px]">
                    <div className="flex justify-between items-center">
                        <span className="text-[16px] text-dark_grey font-[500p]">Wähle ein Produkt:</span>
                        <SelectInput initValue={product.value} size="sm" options={filterOptions} changeHandler={(option) => { setProduct(option) }} />
                    </div>
                    <TextArea initValue={question} size="lg" placeholder="Schreibe eine Frage" changeHandler={(value) => { setQuestion(value) }} />
                    <div className="flex gap-[20px] items-center">
                        <span className="text-[16px] text-dark_grey font-[500p]">Typ:</span>
                        <SelectInput initValue={type.value} size="sm" options={question_type_options} changeHandler={(option) => { setType(option) }} />
                    </div>
                    {type.value == "Select One" &&
                        <>
                            <div className="h-[1px] bg-light_grey" />
                            <div className="flex gap-[10px]">
                                <Input initValue={currentInputAnswer} size="lg" placeholder="Antwort schreiben" changeHandler={(value) => { setCurrentInputAnswer(value) }} />
                                <button onClick={(e) => { onAddAnswer() }} className="min-w-[40px] h-[40px] flex justify-center items-center bg-light_blue rounded-full p-[10px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <g clip-path="url(#clip0_150_354)">
                                            <path d="M7 0.540039V13.54" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M0.5 7H13.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_150_354">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                            <div className="flex flex-col gap-[10px]">
                                {selectAnswers.map((selectAnswer, index) => {
                                    return (
                                        <div className="font-mulish flex gap-[10px] items-center">
                                            <span className="w-full text-[16px] text-dark_grey font-[400]">{selectAnswer}</span>
                                            <RemoveButton clickHandler={(e) => { onDelectAnswer(index) }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }
                    {type.value == "Upload Document" &&
                        <>
                            <div className="h-[1px] bg-light_grey" />
                            <div className="flex gap-[10px]">
                                <Input initValue={currentInputAnswer} size="lg" placeholder="Dokument schreiben" changeHandler={(value) => { setCurrentInputAnswer(value) }} />
                                <button onClick={(e) => { onAddDocument() }} className="min-w-[40px] h-[40px] flex justify-center items-center bg-light_blue rounded-full p-[10px]">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <g clip-path="url(#clip0_150_354)">
                                            <path d="M7 0.540039V13.54" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M0.5 7H13.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_150_354">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                            <div className="flex flex-col gap-[10px]">
                                {documents.map((document, index) => {
                                    return (
                                        <div className="font-mulish flex gap-[10px] items-center">
                                            <CheckBox initValue={document.required} clickHandler={(e, checked) => { let tmp = [...documents]; tmp[index].required = checked; setDocuments(tmp); }} />
                                            <span className="w-full text-[16px] text-dark_grey font-[400]">{document.name}</span>
                                            <RemoveButton clickHandler={(e) => { onDelectDocument(index) }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }
                </div>
                <Button mode="light" size="sm" label="Speichern" clickHandler={(e) => { onSave() }} />
            </div>
        </div>
    );
}

export default EditQuestion;